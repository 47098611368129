import { useState } from "react";

export default function GenerateTableConfig(dataObj, headers, clickHandler) {
  const [selectedRow, setSelectedRow] = useState();

  const clickHandle = (rowId) => {
    setSelectedRow(rowId);
    if (clickHandler) {
      clickHandler(rowId);
    }
  };
  const tableConfig = {
    data: dataObj,
    selectedId: selectedRow,
    clickHandler: clickHandle,
    headers,
  };

  return tableConfig;
}
