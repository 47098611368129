import React from "react";
import { Link } from "react-router-dom";
import { Menu, Image, Icon, Popup } from "semantic-ui-react";
import { navItems } from "config/generalConfig";
import { useRecoilValue } from "recoil";
import { navbarOpenState } from "store";
import usePermissions from "services/usePermissions";
import classNames from "classnames";

const SideNav = () => {
  const navState = useRecoilValue(navbarOpenState);
  const navBarStyle = classNames("left-navbar", { open: navState });
  const items = navItems.map((item) => <GetItem item={item} key={item.title} />);

  return (
    <Menu vertical inverted className={navBarStyle} data-test="navBar">
      <Menu.Item link>
        <Image className="navbar-logo" size="small" src="/CQLogoMini.svg" />
      </Menu.Item>
      {items}
      {/* <Menu.Item link onClick={setNav} className="navbar-burger">
        <Icon className="navbar-icon" name="bars" />
      </Menu.Item> */}
    </Menu>
  );
};

const GetItem = ({ item }) => {
  const { hasAnyPerms } = usePermissions({ route: item.optionsRoute });
  return hasAnyPerms ? <SideNavItem key={item.title} item={item} /> : null;
};

const SideNavItem = ({ item }) => (
  <Popup
    trigger={
      <Menu.Item
        data-test={`navItem${item.title}`}
        id={item.title}
        as={Link}
        to={item.url}
      >
        <Icon className="navbar-icon" name={item.icon} />
        <span className="nav-item-text">{item.title}</span>
      </Menu.Item>
    }
    content={item.title}
    position="right center"
  />
);

export default SideNav;
