import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Form } from "semantic-ui-react";
import { fieldStateSelector } from "store/formState";

export const SelectInput = ({ fieldId, fieldName, setVal, ...props }) => {
  const [fieldState, setFieldState] = useRecoilState(fieldStateSelector(fieldId));

  useEffect(() => {
    /* istanbul ignore else */
    if (setVal) {
      setFieldState({ type: "setVal", value: setVal });
    }
  }, [setVal, setFieldState]);

  return (
    <Form.Dropdown
      id={fieldId}
      placeholder="Please Select"
      fluid
      selection
      name={fieldName}
      onChange={(e, { value }) => setFieldState({ type: e.type, value })}
      onBlur={(e, { value }) => setFieldState({ type: e.type, value })}
      value={fieldState?.value}
      error={
        fieldState?.errors && {
          content: fieldState?.errors?.errMessage[0],
          pointing: "above",
        }
      }
      {...props}
    >
      {props.children}
    </Form.Dropdown>
  );
};

export default SelectInput;
