import { ROUTES } from "services/httpService";
// TODO: finish router config & iterate where needed
// export const routerRoutes = {
//   root: "/",
//   orgs: {
//     list: "/orgs",
//     details: {
//       root: (orgId) => `/orgs/${orgId}`,
//       projects: {
//         list: (orgId) => `/orgs/${orgId}/projects`,
//         details: (orgId, projectId) => `/orgs/${orgId}/projects/${projectId}`,
//       },
//       machines: {},
//       users: {},
//     },
//   },
//   globalUsers: "/users",
// };

export const navItems = [
  {
    title: "Organizations",
    icon: "building",
    url: "/organizations",
    optionsRoute: ROUTES.getOrgListOptions,
  },
  {
    title: "Machines",
    icon: "disk",
    url: "/machines",
    optionsRoute: ROUTES.getMachineOptions,
  },
  {
    title: "Users",
    icon: "users",
    url: "/users",
    optionsRoute: ROUTES.getUserOptions,
  },
];

export const permsMap = {
  add: "create",
  list: "list",
  edit: "update",
  delete: "delete",
  get: "get",
  addReservation: "reserve_block_create",
};

export const machineStatusType = {
  available: "available",
  runner: "runner_status",
};

export const machineOnlineStatus = {
  true: { color: "green", text: "Online" },
  false: { color: "red", text: "Offline" },
  unknown: { color: "grey", text: "Unknown online status" },
};

export const machineRunnerStatus = {
  true: { color: "green", text: "Accepting jobs" },
  false: { color: "red", text: "Not accepting jobs" },
  unknown: { color: "grey", text: "Unknown job runnner status" },
};

export const jobStatusLabels = {
  PENDING: { color: "grey", text: "PENDING" },
  RUNNING: { color: "blue", text: "RUNNING" },
  COMPLETE: { color: "green", text: "COMPLETE" },
  FAILED: { color: "red", text: "FAILED" },
  CANCELLED: { color: "red", text: "CANCELLED" },
};
