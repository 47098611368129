import { atom, selector, selectorFamily } from "recoil";
import { makeRequest, ROUTES } from "services/httpService";
import { refetchIdFamily } from "store/formState";
import { refetchKeys } from "config/formConfig";
import { isValid } from "date-fns";

export const machineSelectedState = atom({
  key: "machineSelectedState",
  default: null,
});

export const getMachinesList = selector({
  key: "getMachinesList",
  get: async () => {
    const res = await makeRequest(ROUTES.getMachinesList);
    const machines = res.data ? res.data.machines : /* istanbul ignore next */ [];
    const machinesById = machines.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

    return {
      machines,
      machinesById,
      getMachineDetails: (id) => machinesById?.[id],
    };
  },
});

export const getMachineOpen = selectorFamily({
  key: "getMachineOpen",
  get:
    ({ machineId, start, end, noConflicts }) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getOrgProjectMachinesScheduleReservations));
      get(refetchIdFamily(refetchKeys.getDowntimeList));
      /* istanbul ignore if */
      if (!isValid(start)) {
        throw new TypeError(
          `start must be an instance of Date() but was instead ${typeof start}`
        );
      }
      /* istanbul ignore if */
      if (!isValid(end)) {
        throw new TypeError(
          `end must be instance of Date() but was instead ${typeof end}`
        );
      }

      const res = await makeRequest(
        ROUTES.getMachineOpen({ machineId, start, end, noConflicts })
      );

      return res.data ? res.data.blocks : /* istanbul ignore next */ [];
    },
});

export const downtimeSelectedState = atom({
  key: "downtimeSelectedState",
  default: null,
});

export const getDowntimeOverlap = selectorFamily({
  key: "getDowntimeOverlap",
  get:
    ({ machineId, start, end }) =>
    async () => {
      const res = await makeRequest(
        ROUTES.getDowntimeOverlap(machineId, start, end)
      );
      const overlap = res.data ? res.data.blocks : /* istanbul ignore next */ [];
      return { overlap };
    },
});

export const getDowntimeList = selectorFamily({
  key: "getDowntimeList",
  get:
    ({ machineId, start, end }) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getDowntimeList));
      const res = await makeRequest(
        ROUTES.getDowntimeList({
          machineId,
          start,
          end,
        })
      );
      const blocks = res.data ? res.data.blocks : /* istanbul ignore next */ [];
      const blocksById = blocks.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});
      return { blocks, blocksById };
    },
});

export const getMachineStatus = selectorFamily({
  key: "getMachineStatus",
  get:
    ({ machineId }) =>
    async () => {
      const res = await makeRequest(ROUTES.getMachineStatus(machineId));
      /* istanbul ignore if */
      if (res.status !== 200) {
        return null;
      }

      return res.data ? res.data : /* istanbul ignore next */ {};
    },
});
