import { atom, selectorFamily } from "recoil";
import { makeRequest, ROUTES } from "services/httpService";
import { refetchIdFamily } from "store/formState";
import { refetchKeys } from "config/formConfig";

export const jobSelectedState = atom({
  key: "jobSelectedState",
  default: null,
});

export const getJob = selectorFamily({
  key: "getJob",
  get:
    (params) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getProjectMachineJobs));
      const res = await makeRequest(ROUTES.getOrgProjectMachineJob(params));
      return res.data?.id ? res.data : /* istanbul ignore next */ {};
    },
});
