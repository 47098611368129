import React from "react";
import { ToggleInput, SelectInput } from "components/common/form";

const priorityOptions = [
  {
    key: 0.1,
    text: 0.1,
    value: 0.1,
  },
  {
    key: 0.5,
    text: 0.5,
    value: 0.5,
  },
  {
    key: 0.7,
    text: 0.7,
    value: 0.7,
  },
];

const MachineAccessToggles = ({ data }) => (
  <>
    <ToggleInput
      fieldId="accessEnabled"
      fieldName="accessEnabled"
      label="Access Enabled"
      setVal={data?.accessEnabled}
    />
    <ToggleInput
      fieldId="blockSchedulingEnabled"
      fieldName="blockSchedulingEnabled"
      label="Block Scheduling"
      setVal={data?.blockSchedulingEnabled}
    />
    <ToggleInput
      fieldId="prioritySchedulingEnabled"
      fieldName="prioritySchedulingEnabled"
      label="Priority Scheduling"
      setVal={data?.prioritySchedulingEnabled}
    />
    <SelectInput
      // disabled={!values?.priority_scheduling_enabled}
      fieldId="defaultPriority"
      fieldName="defaultPriority"
      label="Default Priority"
      options={priorityOptions}
      setVal={data?.defaultPriority ?? 0.1}
    />
  </>
);

export default MachineAccessToggles;
