import React from "react";
import useAuth from "services/useAuth";
import { Loading } from "components/common/ui";
import SideNav from "./SideNav";
import TopBar from "./TopBar";

const Layout = ({ children }) => {
  const { authState } = useAuth();

  return !authState.isAuthenticated || authState.isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="left-pane" data-test="layout-left-pane">
        <SideNav />
      </div>
      <div className="right-pane" data-test="layout-right-pane">
        <TopBar />
        <div className="page-pane" data-test="layout-page-pane">
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
