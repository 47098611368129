import { Auth0Client } from "@auth0/auth0-spa-js";

// eslint-disable-next-line
let auth = {
  getTokenSilently: /* istanbul ignore next */ () => null,
  isAuthenticated: /* istanbul ignore next */ () => null,
  getUser: async () => ({
    nickname: "alice",
    name: "alice@coldquanta.com",
    picture:
      "https://s.gravatar.com/avatar/0ce273d3249291c620af81403b14b3c1?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fal.png",
    updated_at: "2022-03-25T17:05:47.393Z",
    email: "alice@coldquanta.com",
  }),
  loginWithRedirect: /* istanbul ignore next */ () => null,
  logout: () => null,
};

// workaround for headless browsers so they don't init the client
/* istanbul ignore if */
if (
  !navigator.userAgent.includes("jsdom") &&
  process.env.REACT_APP_AUTH0_DOMAIN !== "cypress"
) {
  const auth0 = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirect_uri: window.location.origin,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
    cacheLocation: "localstorage",
  });

  auth = {
    getTokenSilently: async () => auth0.getTokenSilently(),
    isAuthenticated: async () => auth0.isAuthenticated(),
    getUser: async () => auth0.getUser(),
    loginWithRedirect: async () => auth0.loginWithRedirect(),
    logout: async () =>
      auth0.logout({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        returnTo: window.location.origin,
      }),
  };
}

export default auth;
