import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { editDrawerOpenState } from "store";

import { Button } from "semantic-ui-react";

const classNames = require("classnames");

const EditDrawer = ({ drawerAtom = "defaultEditDrawerAtom", children }) => {
  const [drawerState, setDrawerState] = useRecoilState(editDrawerOpenState);
  const location = useLocation();

  useEffect(() => {
    setDrawerState(false);
  }, [location, setDrawerState]);

  const editDrawerStyles = classNames("page-content-drawer", {
    open: drawerState,
  });

  return (
    <div data-test={drawerAtom} className={editDrawerStyles}>
      <Button
        data-test="edit-drawer-close-button"
        className="drawer-close-btn"
        circular
        icon="angle right"
        onClick={() => setDrawerState(false)}
      />
      {children}
    </div>
  );
};

export default EditDrawer;
