import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { getUserByEmail } from "store";
import { ToggleInput, StrInput, FormButtons } from "components/common/form";
import UserLookup from "./UserLookup";

const OrgUserAdd = ({ formControl }) => {
  const [searchEmail, setSearchEmail] = useState();

  const handleSearchEmail = () =>
    formControl.formData.userEmail && setSearchEmail(formControl.formData.userEmail);

  const userFound = useRecoilValue(getUserByEmail(searchEmail));

  return (
    <>
      <UserLookup
        userLookupCallback={handleSearchEmail}
        userFound={userFound}
        searchEmail={searchEmail}
      />
      <UserAddForm
        formControl={formControl}
        userFound={userFound}
        searchEmail={searchEmail}
        userLookupCallback={handleSearchEmail}
      />
    </>
  );
};

const UserAddForm = ({ formControl, userFound, searchEmail }) => (
  <>
    <StrInput
      fieldId="userName"
      fieldName="userName"
      label="Name"
      disabled={!searchEmail}
      readOnly={userFound?.id}
      setVal={userFound?.name ?? ""}
    />
    <StrInput
      fieldId="userId"
      fieldName="userId"
      label="User Id"
      disabled={!searchEmail}
      readOnly
      setVal={userFound?.id ?? "New-User"}
    />
    <ToggleInput
      fieldId="isOrgAdmin"
      fieldName="isOrgAdmin"
      label="User is Org admin"
      setVal={userFound?.groups.cq_admin}
      disabled={userFound?.groups.cq_admin}
    />
    <FormButtons formControl={formControl} />
  </>
);

export default OrgUserAdd;
