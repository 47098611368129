import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  modalOpenState,
  editDrawerOpenState,
  getOrgUsersList,
  userSelectedState,
} from "store";
import { Form } from "semantic-ui-react";
import useForm from "services/useForm";
import { HiddenInput } from "components/common/form";
import OrgUserAdd from "./OrgUserAdd";
import OrgUserEdit from "./OrgUserEdit";
import OrgUserDelete from "./OrgUserDelete";

const OrgUsersForm = ({ orgId, formAction, formConfig }) => {
  const { getOrgUserDetails } = useRecoilValue(getOrgUsersList({ orgId }));
  const currentUser = useRecoilValue(userSelectedState);
  const user = getOrgUserDetails(currentUser);

  const setModalState = useSetRecoilState(modalOpenState(formConfig.formName));
  const setDrawerState = useSetRecoilState(editDrawerOpenState);

  const handeDrawerChange = () => formAction !== "edit" && setDrawerState(false);

  const postSubmitCleanup = () => {
    setModalState(false);
    handeDrawerChange();
  };

  const { submit, reset, isInvalid, formData } = useForm({
    formConfig: formConfig[formAction],
    postSubmitCleanup,
  });

  const formControl = { formAction, submit, reset, isInvalid, formData };

  const FormFields = {
    add: <OrgUserAdd formControl={formControl} />,
    edit: <OrgUserEdit formControl={formControl} data={user} />,
    delete: <OrgUserDelete formControl={formControl} data={user} />,
  };

  return (
    <Form>
      {FormFields[formAction]}
      <HiddenInput fieldId="orgId" fieldName="orgId" setVal={orgId} />
    </Form>
  );
};

export default OrgUsersForm;
