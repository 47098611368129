import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Form, TextArea } from "semantic-ui-react";
import { fieldStateSelector } from "store/formState";

export const TextAreaInput = ({ fieldId, fieldName, setVal, ...props }) => {
  const [fieldState, setFieldState] = useRecoilState(fieldStateSelector(fieldId));

  useEffect(() => {
    /* istanbul ignore else */
    if (setVal) {
      setFieldState({ type: "setVal", value: setVal });
    }
  }, [setVal, setFieldState]);

  return (
    <Form.Field
      control={TextArea}
      id={fieldId}
      name={fieldName}
      onChange={(e) => setFieldState({ type: e.type, value: e.target.value })}
      onBlur={(e) => setFieldState({ type: e.type, value: e.target.value })}
      value={fieldState.value ?? ""}
      error={
        fieldState?.errors && {
          content: fieldState?.errors?.errMessage[0],
          pointing: "above",
        }
      }
      {...props}
    />
  );
};

export default TextAreaInput;
