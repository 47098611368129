import React from "react";
import { useSetRecoilState } from "recoil";
import { modalOpenState } from "store";
import { Form } from "semantic-ui-react";
import useForm from "services/useForm";
import OrgAdd from "./OrgAdd";

const OrgForm = ({ formAction, formConfig }) => {
  const setModalState = useSetRecoilState(modalOpenState(formConfig.formName));

  const postSubmitCleanup = () => {
    setModalState(false);
  };

  const { submit, reset, isInvalid } = useForm({
    formConfig: formConfig[formAction],
    postSubmitCleanup,
  });

  const formControl = {
    formName: formConfig.formName,
    formAction,
    submit,
    reset,
    isInvalid,
  };

  const FormFields = {
    add: <OrgAdd formControl={formControl} />,
  };

  return <Form>{FormFields[formAction]}</Form>;
};

export default OrgForm;
