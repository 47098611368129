import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { validateForm, formDataSelector, refetchIdFamily } from "store/formState";
import { makeRequest } from "services/httpService";
import { toast } from "react-toastify";

/**
 * Hook middleware between form components and recoil to share and manage the common form state &
 * utility functions. This is more flexible than local state or using react hooks without recoil.
 *
 * @param {object} formConfig - object defining requestRoute, refetchKey, formFields & success/fail text
 * @param {object} formConfig.requestRoute - object containing http request type, url, pararms, & body
 * @param {string} formConfig.refetchKey - Recoil Atom Family Key to trigger a GET operation after successful submission
 * @param {array} formConfig.fields - Array of fieldIds to generate Recoil Atoms to track each field state
 * @param {object} formConfig.messages - Default UI message displayed after submit success/error
 *
 * @param {callback} postSubmitCleanup - required callback to handle ui changes after submit/reset
 * @param {callback} [overrideResponse] - optional callback to override default success/error messages
 *
 * @returns {array} useForm - exposes utility functions to be used by any form
 */
const useForm = ({ formConfig, postSubmitCleanup, overrideResponse }) => {
  const { requestRoute, refetchKey, fields, messages } = formConfig;

  /** @const {function} formData - grabs all of the form field values */
  /** @const {function} setFormData - resets all of the form field values */
  const [formData, resetFormData] = useRecoilState(formDataSelector({ fields }));

  /** @const {function} refetch - triggers a refetch to perform another GET after a POST/PATCH/DELETE is successful */
  const refetch = useSetRecoilState(refetchIdFamily(refetchKey));

  /** @const {boolean} isInvalid - used to enable/disable the submit button */
  const isInvalid = useRecoilValue(validateForm({ fields }));

  /** reset the form fields & fire the callback for UI effects */
  const reset = () => {
    /* istanbul ignore else */
    if (postSubmitCleanup) {
      postSubmitCleanup();
    }
    resetFormData();
  };

  /** if override response is passed, use it, otherwise fall back to default success/error messages */
  const switchRes = (res, type) =>
    !overrideResponse ? toast[type](messages[type]) : overrideResponse(res);

  /** handles generic form submit control flow */
  const submit = async () => {
    const res = await makeRequest(requestRoute(formData));
    if (res.status !== 200) {
      switchRes(res, "error");
    } else {
      reset();
      refetch((prev) => prev + 1);
      switchRes(res, "success");
    }
  };
  return { submit, reset, isInvalid, formData };
};

export default useForm;
