import { atom, selector, selectorFamily } from "recoil";
import { refetchIdFamily } from "store/formState";
import { refetchKeys } from "config/formConfig";
import { makeRequest, ROUTES } from "services/httpService";

export const userSelectedState = atom({
  key: "userSelectedState",
  default: null,
});

export const newUserState = atom({
  key: "newUserState",
  default: {
    name: "",
    email: "",
    id: "",
    groups: {
      cq_admin: false,
    },
  },
});

export const getUserByEmail = selectorFamily({
  key: "getUserByEmail",
  get: (searchEmail) => async () => {
    if (searchEmail) {
      const res = await makeRequest(ROUTES.getUserByEmail(searchEmail));
      return res.data;
    }
    return undefined;
  },
});

export const getUsersList = selector({
  key: "getUsersList",
  get: async ({ get }) => {
    get(refetchIdFamily(refetchKeys.getUserList));
    const res = await makeRequest(ROUTES.getUsersList);
    const users = res.data ? res.data.users : /* istanbul ignore next */ [];
    const usersById = users.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

    return {
      users,
      usersById,
      getUserDetails: (id) => usersById?.[id],
    };
  },
});
