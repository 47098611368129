import React from "react";
import { Label } from "semantic-ui-react";

const StatusLabel = ({ status, statusTypeMap, size = "medium", basic }) => (
  <Label basic={basic} size={size} color={statusTypeMap[status].color}>
    {statusTypeMap[status].text}
  </Label>
);

export default StatusLabel;
