import React from "react";
import {
  StrInput,
  HiddenInput,
  ToggleInput,
  FormButtons,
} from "components/common/form";

const OrgUserEdit = ({ data, ...props }) => {
  const formData = {
    userId: data?.id,
    userName: data?.name,
    isOrgAdmin: data?.is_org_admin,
    isCQAdmin: data?.is_cq_admin,
  };

  return <UserEditForm formData={formData} {...props} />;
};

const UserEditForm = ({ formControl, formData }) => (
  <>
    <HiddenInput fieldId="userId" fieldName="userId" setVal={formData?.userId} />
    <StrInput
      fieldId="userName"
      fieldName="name"
      label="Name"
      readOnly={formData?.userName}
      setVal={formData?.userName}
    />
    <ToggleInput
      fieldId="isOrgAdmin"
      fieldName="isOrgAdmin"
      label="User is Org admin"
      setVal={formData?.isOrgAdmin}
      disabled={formData?.isCQAdmin}
    />
    <FormButtons formControl={formControl} />
  </>
);

export default OrgUserEdit;
