import React from "react";
import { Table } from "semantic-ui-react";
import TableRows from "./TableRows";
import HeaderCells from "./TableHeader";

const TableMain = ({ tableConfig, testTarget, tableClass }) => (
  <Table className={tableClass} striped fixed singleLine data-test={testTarget}>
    <Table.Header>
      <Table.Row>{HeaderCells(tableConfig)}</Table.Row>
    </Table.Header>
    <Table.Body>{TableRows(tableConfig)}</Table.Body>
  </Table>
);

export default TableMain;
