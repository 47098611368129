import axios from "axios";
import apiSpec from "config/apiSpec";
import logger from "./logService";
import auth from "./auth0Service";

export const ROUTES = apiSpec.routes;

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setJwt = /* istanbul ignore next */ (jwt) => {
  if (jwt) {
    api.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }
};

export const clearJwt = /* istanbul ignore next */ () => {
  api.defaults.headers.common.Authorization = "";
};

const handleErrors = (error) => {
  const logItems = [];
  /* istanbul ignore else */
  if (error.response) {
    switch (error.response.status) {
      case 401:
        logItems.push({
          type: "warn",
          consoleMsg: `${error.response.status} ${error.response.statusText}`,
          displayMsg: "Request Not Authorized",
        });
        break;
      case 403:
        logItems.push({
          type: "warn",
          consoleMsg: `${error.response.status} ${error.response.statusText}`,
          displayMsg: "Request Forbidden",
        });
        break;
      /* istanbul ignore next */
      default:
        logItems.push({
          type: "warn",
          consoleMsg: `${error.response.status} ${error.response.statusText}`,
          displayMsg: "Something went wrong with the request",
        });
    }
  } else {
    /* istanbul ignore next */
    logItems.push({
      type: "error",
      consoleMsg: `${error.name}: ${error.message}`,
    });
  }

  /* istanbul ignore else */
  if (process.env.REACT_APP_LOGGING === "enabled") {
    logger.log(logItems);
  }

  return Promise.reject(error);
};

// NOTE: mockUsers are used in local development to make api requests
const mockUsers = {
  alice: "alice",
  bob: "bob",
  kurt: "kurt",
};

const setAuthHeader = async (request) => {
  let token;

  /* istanbul ignore if: path will never be taken in coverage reporting */
  if (process.env.NODE_ENV !== "development") {
    token = await auth.getTokenSilently();
    if (!token) {
      await auth.loginWithRedirect();
    }
  } else {
    token = mockUsers[process.env.REACT_APP_CURRENT_MOCK_USER];
  }

  request.headers.Authorization = `Bearer ${token}`;

  return request;
};

export const initInterceptors = () => {
  api.interceptors.request.use(
    (request) => setAuthHeader(request),
    (error) => handleErrors(error)
  );
  api.interceptors.response.use(
    (response) => response,
    (error) => handleErrors(error)
  );
};

export const makeRequest = async (call) => {
  try {
    const response = await api[call.type](call.url, call.requestBody, {
      params: call.params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const createRequest = ({ route }) => {
  let pageToken = "";

  async function getData() {
    if (pageToken !== null) {
      const res = await makeRequest(route(pageToken));
      pageToken = res.data?.next_page_token || null;
      const data = res.data || [];
      return data;
    }
    return { data: null };
  }

  return getData;
};

initInterceptors();
