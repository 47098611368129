import React from "react";
import { StrInput } from "components/common/form";

import { Button, Form, Message } from "semantic-ui-react";

const UserLookup = ({ userLookupCallback, userFound, searchEmail }) => (
  <>
    <p>
      Please use the search box to select the user you wish to add. Only exact email
      match is supported at this time.
    </p>
    <Form.Group inline widths="equal">
      <StrInput fieldId="userEmail" fieldName="userEmail" label="Email" />
      <Button
        data-test="form_lookup_email_search_submit_button"
        content="Search"
        size="tiny"
        className="emailSearch"
        id="emailSearch"
        primary
        type="submit"
        onClick={userLookupCallback}
      />
    </Form.Group>
    <UserSearchResult userFound={userFound} email={searchEmail} />
  </>
);

const UserSearchResult = ({ userFound, email }) => {
  if (userFound === null) {
    return (
      <Message info>
        <p>
          {email} was not found in our system. Please enter their name and they will
          receive an email invitation.
        </p>
      </Message>
    );
  }
  if (userFound) {
    return (
      <Message positive>
        <p>
          {email} was found in our system. They will be added to the organization.
        </p>
      </Message>
    );
  }
  return null;
};

export default UserLookup;
