import * as Yup from "yup";
import { ROUTES } from "services/httpService";
import {
  isValid,
  getTime,
  addMinutes,
  addDays,
  roundToNearestMinutes,
} from "date-fns";

/**
 * Note: when adding form objects, reference the appropriate refetch key.
 *
 * The refetch key a reference to a recoil atom which increments by 1 to trigger
 * any selectors that are subscribed to it to re-evaluate.
 *
 * The recoil atom increments, causing the selector to re-evalate and perform
 * another GET after a POST/PATCH/DELETE is successful.
 *
 * @example
 * 'orgAddForm' needs to trigger getOrgList to re-evaluate (perform GET to list orgs).
 *
 * The 'useForm' hook uses the refetch key to reference the same refetchIdFamily that the
 * 'getOrgList' selector is subscribed to.
 *
 * When "useForm" calls refetchIdFamily, it increments by 1.
 * Then 'getOrgList' re-evaluates because it is subscribed to the same refetchIdFamily.
 */

export const refetchKeys = {
  getUserList: "getUserList",
  getOrgList: "getOrgList",
  getOrgUserList: "getOrgUserList",
  getOrgMachineAccessList: "getOrgMachineAccessList",
  getProjectsList: "getProjectsList",
  getProjectUsersList: "getProjectUsersList",
  getOrgProjectMachinesScheduleReservations:
    "getOrgProjectMachinesScheduleReservations",
  getOrgProjectMachineAccess: "getOrgProjectMachineAccess",
  getMachineOpen: "getMachineOpen",
  getDowntimeList: "getDowntimeList",
  getProjectMachineJobs: "getProjectMachineJobs",
};

const formConfigLib = {
  refetchKeys: { ...refetchKeys },
  orgForm: {
    formName: "orgForm",
    add: {
      requestRoute: ROUTES.postOrg,
      refetchKey: refetchKeys.getOrgList,
      fields: ["orgName"],
      messages: {
        success: "Organization sucessfully added",
        error: "There was an error adding the organization",
      },
    },
    lookup: {
      requestRoute: ROUTES.getUserById,
      fields: ["userEmail"],
      messages: {
        success: "User Found",
        error: "No user found",
      },
    },
  },
  userForm: {
    formName: "userForm",
    add: {
      requestRoute: ROUTES.postUser,
      refetchKey: refetchKeys.getUserList,
      fields: ["userName", "userEmail", "isAdmin", "isOperator", "isAccounting"],
      messages: {
        success: "User sucessfully added",
        error: "There was an error adding the user",
      },
    },
    edit: {
      requestRoute: ROUTES.putUserGroups,
      refetchKey: refetchKeys.getUserList,
      fields: [
        "userId",
        "userName",
        "userEmail",
        "isAdmin",
        "isOperator",
        "isAccounting",
      ],
      messages: {
        success: "User sucessfully edited",
        error: "There was an error editing the user",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteUser,
      refetchKey: refetchKeys.getUserList,
      fields: ["userId"],
      messages: {
        success: "User sucessfully deleted",
        error: "There was an error deleting the user",
      },
    },
  },
  orgUserForm: {
    formName: "orgUserForm",
    add: {
      requestRoute: ROUTES.postOrgUser,
      refetchKey: refetchKeys.getOrgUserList,
      fields: ["orgId", "userId", "isOrgAdmin", "findUser", "userEmail", "userName"],
      messages: {
        success: "User sucessfully added",
        error: "There was an error adding the user",
      },
    },
    edit: {
      requestRoute: ROUTES.putOrgUser,
      refetchKey: refetchKeys.getOrgUserList,
      fields: ["orgId", "userId", "isOrgAdmin"],
      messages: {
        success: "User sucessfully edited",
        error: "There was an error editing the user",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteOrgUser,
      refetchKey: refetchKeys.getOrgUserList,
      fields: ["userId", "orgId"],
      messages: {
        success: "User sucessfully deleted",
        error: "There was an error deleting the user",
      },
    },
  },
  orgMachineAccessForm: {
    formName: "orgMachineAccessForm",
    add: {
      requestRoute: ROUTES.postOrgMachineAccess,
      refetchKey: refetchKeys.getOrgMachineAccessList,
      fields: [
        "orgId",
        "machineId",
        "accessEnabled",
        "blockSchedulingEnabled",
        "prioritySchedulingEnabled",
        "defaultPriority",
      ],
      messages: {
        success: "Machine sucessfully added",
        error: "There was an error adding the machine",
      },
    },
    edit: {
      requestRoute: ROUTES.patchOrgMachineAccess,
      refetchKey: refetchKeys.getOrgMachineAccessList,
      fields: [
        "orgId",
        "machineId",
        "accessEnabled",
        "blockSchedulingEnabled",
        "prioritySchedulingEnabled",
        "defaultPriority",
      ],
      messages: {
        success: "Machine sucessfully edited",
        error: "There was an error editing the machine",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteOrgMachineAccess,
      refetchKey: refetchKeys.getOrgMachineAccessList,
      fields: ["orgId", "machineId"],
      messages: {
        success: "Machine sucessfully deleted",
        error: "There was an error deleting the machine",
      },
    },
  },
  projectForm: {
    formName: "projectForm",
    add: {
      requestRoute: ROUTES.postProjectsList,
      refetchKey: refetchKeys.getProjectsList,
      fields: ["orgId", "projectName"],
      messages: {
        success: "Project sucessfully added",
        error: "There was an error adding the project",
      },
    },
    edit: {
      requestRoute: ROUTES.patchProjectDetails,
      refetchKey: refetchKeys.getProjectsList,
      fields: ["orgId", "projectId", "projectName"],
      messages: {
        success: "Project sucessfully edited",
        error: "There was an error editing the project",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteProject,
      refetchKey: refetchKeys.getProjectsList,
      fields: ["orgId", "projectId"],
      messages: {
        success: "Project sucessfully deleted",
        error: "There was an error deleting the project",
      },
    },
  },
  projectUserForm: {
    formName: "projectUserForm",
    add: {
      requestRoute: ROUTES.postProjectUser,
      refetchKey: refetchKeys.getProjectUsersList,
      fields: ["userId", "orgId", "projectId"],
      messages: {
        success: "User sucessfully added",
        error: "There was an error adding the user",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteProjectUser,
      refetchKey: refetchKeys.getProjectUsersList,
      fields: ["userId", "orgId", "projectId"],
      messages: {
        success: "User sucessfully deleted",
        error: "There was an error deleting the user",
      },
    },
  },
  projectMachineAccessForm: {
    formName: "projectMachineAccessForm",
    add: {
      requestRoute: ROUTES.postOrgProjectMachineAccess,
      refetchKey: refetchKeys.getOrgProjectMachineAccess,
      fields: [
        "orgId",
        "projectId",
        "machineId",
        "accessEnabled",
        "blockSchedulingEnabled",
        "prioritySchedulingEnabled",
        "defaultPriority",
      ],
      messages: {
        success: "Machine sucessfully added",
        error: "There was an error adding the machine",
      },
    },
    edit: {
      requestRoute: ROUTES.patchOrgProjectMachineAccess,
      refetchKey: refetchKeys.getOrgProjectMachineAccess,
      fields: [
        "orgId",
        "projectId",
        "machineId",
        "accessEnabled",
        "blockSchedulingEnabled",
        "prioritySchedulingEnabled",
        "defaultPriority",
      ],
      messages: {
        success: "Machine sucessfully edited",
        error: "There was an error editing the machine",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteOrgProjectMachineAccess,
      refetchKey: refetchKeys.getOrgProjectMachineAccess,
      fields: ["orgId", "projectId", "machineId"],
      messages: {
        success: "Machine sucessfully deleted",
        error: "There was an error deleting the machine",
      },
    },
  },
  projectScheduleFilterForm: {
    formName: "projectScheduleFilterForm",
    requestRoute: ROUTES.getOrgProjectMachinesScheduleReservations,
    refetchKey: refetchKeys.getOrgProjectMachinesScheduleReservations,
    fields: ["schedStart", "schedEnd"],
    messages: {
      success: "Maybe found some open blocks",
      error: "There was an error finding open blocks",
    },
  },
  projectReservationForm: {
    formName: "reservationForm",
    add: {
      requestRoute: ROUTES.postOrgProjectMachinesScheduleReservation,
      refetchKey: refetchKeys.getOrgProjectMachinesScheduleReservations,
      fields: [
        "machineId",
        "projectId",
        "orgId",
        "findOpenStart",
        "findOpenEnd",
        "resvStart",
        "resvEnd",
        "resvMessage",
      ],
      messages: {
        success: "Reservation was created sucessfully",
        error: "There was an error creating the reservation",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteOrgProjectMachinesScheduleReservation,
      refetchKey: refetchKeys.getOrgProjectMachinesScheduleReservations,
      fields: ["scheduleId", "orgId", "projectId", "machineId"],
      messages: {
        success: "The scheduled block was sucessfully deleted",
        error: "There was an error deleting the sheduled block",
      },
    },
  },
  downtimeBlockForm: {
    formName: "downtimeBlockForm",
    add: {
      requestRoute: ROUTES.postDowntimeBlock,
      refetchKey: refetchKeys.getDowntimeList,
      fields: [
        "machineId",
        "findOpenStart",
        "findOpenEnd",
        "blockStart",
        "blockEnd",
        "blockMessage",
      ],
      messages: {
        success: "Downtime block was created sucessfully",
        error: "There was an error creating the downtime block",
      },
    },
    delete: {
      requestRoute: ROUTES.deleteDowntimeBlock,
      refetchKey: refetchKeys.getDowntimeList,
      fields: ["machineId", "blockId"],
      messages: {
        success: "The downtime block was sucessfully deleted",
        error: "There was an error deleting the downtime block",
      },
    },
  },
  jobForm: {
    formName: "jobForm",
    cancel_all: {
      requestRoute: ROUTES.putOrgProjectMachineJobCancelAll,
      refetchKey: refetchKeys.getProjectMachineJobs,
      fields: ["orgId", "projectId", "machineId"],
      messages: {
        success: "All jobs were cancelled successfully",
        error: "There was an error cancelling all jobs",
      },
    },
    cancel: {
      requestRoute: ROUTES.putOrgProjectMachineJobCancel,
      refetchKey: refetchKeys.getProjectMachineJobs,
      fields: ["orgId", "projectId", "machineId", "jobId"],
      messages: {
        success: "Job was cancelled successfully",
        error: "There was an error cancelling the job",
      },
    },
  },
};

/* eslint-disable */
Yup.addMethod(Yup.mixed, "dateCompare", function (operator, msg) {
  return this.test({
    name: "dateCompare",
    message: msg,
    test: function (value) {
      // Test if date is valid with date-fns
      if (!isValid(value)) {
        return this.createError({
          message: "Please enter a valid date",
        });
      }
      // Compare dates
      switch (operator) {
        case "lessThan":
          return value < this.options.context.compareFields.field;
        case "greaterThan":
          return value > this.options.context.compareFields.field;
      }
      return true;
    },
  });
});

Yup.addMethod(Yup.mixed, "dateMinMax", function (operator, msg) {
  return this.test({
    name: "dateMinMax",
    message: msg,
    test: function (value) {
      // Test if date is valid with date-fns
      if (!isValid(value)) {
        return this.createError({
          message: "Please enter a valid date",
        });
      }
      // Compare dates
      switch (operator) {
        case "lessThan":
          return value <= this.options.context.compareFields.max;
        case "greaterThan":
          return value >= this.options.context.compareFields.min;
      }
      return true;
    },
  });
});
/* eslint-enable */

const userFields = {
  userName: Yup.string().max(100).required(),
  userEmail: Yup.string().email().required(),
  findUser: Yup.string().email(),
  isAdmin: Yup.boolean(),
  isOperator: Yup.boolean(),
  isAccounting: Yup.boolean(),
};

const orgUserFields = {
  userId: Yup.string().required(),
  orgId: Yup.string().required(),
};

const orgForm = {
  orgName: Yup.string().max(100).required(),
};

const projectFields = {
  projectName: Yup.string().max(100).required(),
};

const machineFields = {
  machineName: Yup.number().min(0).max(100).required(),
  machineId: Yup.string().max(100).required().nullable(),
  defaultPriority: Yup.number().min(0).max(1),
};

const scheduleFilterFields = {
  schedStart: Yup.number().dateCompare(
    "lessThan",
    "The start date must be lesst than the end date"
  ),
  schedEnd: Yup.number().dateCompare(
    "greaterThan",
    "The end date must be greater than the start date"
  ),
};

const openBlockFields = {
  findOpenStart: Yup.mixed().dateCompare(
    "lessThan",
    "The start date must be lesst than the end date"
  ),
  findOpenEnd: Yup.mixed().dateCompare(
    "greaterThan",
    "The end date must be greater than the start date"
  ),
};

const reservationFields = {
  resvStart: Yup.mixed()
    .required()
    .dateCompare("lessThan", "The start date must be less than the end date")
    .dateMinMax("greaterThan", "The start date must be inside the block range"),
  resvEnd: Yup.mixed()
    .required()
    .dateCompare("greaterThan", "The end date must be greater than the start date")
    .dateMinMax("lessThan", "The end date must be inside the block range"),
  resvMessage: Yup.string().max(100),
};

const scheduleBlockFields = {
  blockStart: Yup.mixed()
    .required()
    .dateCompare("lessThan", "The start date must be less than the end date")
    .dateMinMax("greaterThan", "The start date must be inside the block range"),
  blockEnd: Yup.mixed()
    .required()
    .dateCompare("greaterThan", "The end date must be greater than the start date")
    .dateMinMax("lessThan", "The end date must be inside the block range"),
  blockMessage: Yup.string().max(100),
};

// TODO: inject validationSchema for testing in test setup
// For unit tests
const testingFields = {
  age: Yup.number().max(100),
  testName: Yup.string().required().max(5),
  testEmail: Yup.string().email().required(),
  testTextArea: Yup.string().required(),
};

export const validationSchema = Yup.object().shape(
  {
    ...userFields,
    ...orgUserFields,
    ...orgForm,
    ...projectFields,
    ...machineFields,
    ...openBlockFields,
    ...reservationFields,
    ...scheduleFilterFields,
    ...testingFields,
    ...scheduleBlockFields,
  },
  [["findOpenStart", "findOpenEnd"]]
);

// TODO -> convert scheduling rules to class or hook

export const schedulingRulesLib = {
  getProjectScheduleRules: {
    start: {
      default: getTime(
        roundToNearestMinutes(addMinutes(new Date(), 15), { nearestTo: 15 })
      ),
      minDate: new Date(),
      maxDate: addDays(new Date(), 75),
      timeIntervals: 15,
    },
    end: {
      default: getTime(
        roundToNearestMinutes(addDays(addMinutes(new Date(), 15), 60), {
          nearestTo: 15,
        })
      ),
      minDate: new Date(),
      maxDate: addDays(new Date(), 75),
      timeIntervals: 15,
    },
  },
  getOpenBlocksRules: {
    start: {
      default: getTime(
        roundToNearestMinutes(addMinutes(new Date(), 15), { nearestTo: 15 })
      ),
      minDate: getTime(
        roundToNearestMinutes(addMinutes(new Date(), 15), { nearestTo: 15 })
      ),
      maxDate: addDays(new Date(), 75),
      timeIntervals: 15,
    },
    end: {
      default: getTime(
        roundToNearestMinutes(addDays(addMinutes(new Date(), 15), 30), {
          nearestTo: 15,
        })
      ),
      minDate: getTime(
        roundToNearestMinutes(addMinutes(new Date(), 30), { nearestTo: 15 })
      ),
      maxDate: addDays(new Date(), 75),
      timeIntervals: 15,
    },
  },
  reserveRangeRules: {
    start: {
      default: "",
      timeIntervals: 15,

      placeholderText: "",
    },
    end: {
      default: "",
      timeIntervals: 15,

      placeholderText: "",
    },
  },
  getMachineScheduleRules: {
    start: {
      default: getTime(
        roundToNearestMinutes(addMinutes(new Date(), 15), { nearestTo: 15 })
      ),
      minDate: new Date(),
      maxDate: addDays(new Date(), 75),
      timeIntervals: 15,
    },
    end: {
      default: getTime(
        roundToNearestMinutes(addDays(addMinutes(new Date(), 15), 60), {
          nearestTo: 15,
        })
      ),
      minDate: new Date(),
      maxDate: addDays(new Date(), 75),
      timeIntervals: 15,
    },
  },
};

export default formConfigLib;
