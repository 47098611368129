const apiSpec = {
  routes: {
    getUsersList: {
      type: "get",
      url: "/users?groups=true",
      requestBody: {},
    },
    getUserByEmail: (userEmail) => ({
      type: "get",
      url: `/user/lookup?user_email=${userEmail}`,
    }),
    postUser: ({ userName, userEmail, isAdmin, isOperator, isAccounting }) => ({
      type: "post",
      url: "/users",
      requestBody: {
        email: `${userEmail}`,
        name: `${userName}`,
        groups: {
          cq_admin: isAdmin,
          cq_operator: isOperator,
          cq_accounting: isAccounting,
        },
      },
    }),
    putUserGroups: ({ userId, isAdmin, isOperator, isAccounting }) => ({
      type: "put",
      url: `/users/${userId}/groups`,
      requestBody: {
        cq_admin: isAdmin,
        cq_operator: isOperator,
        cq_accounting: isAccounting,
      },
    }),
    deleteUser: ({ userId }) => ({
      type: "delete",
      url: `/users/${userId}`,
      requestBody: {},
    }),
    getUserOptions: {
      type: "options",
      url: "/users",
      requestBody: {},
    },
    getCQAdminsUsers: {
      type: "get",
      url: "/users/groups/cq_admins",
      requestBody: {},
    },
    postCQAdminUser: (userId) => ({
      type: "post",
      url: "/users/groups/cq_admins",
      requestBody: { user_id: `${userId}` },
    }),
    deleteCQAdminUser: (userId) => ({
      type: "delete",
      url: `/users/groups/cq_admins/${userId}`,
      requestBody: {},
    }),
    getCQAccountingUsers: {
      type: "get",
      url: "/users/groups/cq_accounting",
      requestBody: {},
    },
    postCQAccountingUser: (userId) => ({
      type: "post",
      url: "/users/groups/cq_accounting",
      requestBody: { user_id: `${userId}` },
    }),
    deleteQCAccountingUser: (userId) => ({
      type: "delete",
      url: `/users/groups/cq_accounting/${userId}`,
      requestBody: {},
    }),
    getCQOperatorsUsers: {
      type: "get",
      url: "/users/groups/cq_operators",
      requestBody: {},
    },
    postCQOperatorsUser: (userId) => ({
      type: "post",
      url: "/users/groups/cq_operators",
      requestBody: { user_id: `${userId}` },
    }),
    deleteQCAOperatorsUser: (userId) => ({
      type: "delete",
      url: `/users/groups/cq_operators/${userId}`,
      requestBody: {},
    }),
    getOrgList: {
      type: "get",
      url: "/orgs",
      requestBody: {},
    },
    getOrgListOptions: {
      type: "options",
      url: "/orgs",
      requestBody: {},
    },
    postOrg: ({ orgName }) => ({
      type: "post",
      url: `/orgs`,
      requestBody: { name: `${orgName}` },
    }),
    deleteOrg: (orgId) => ({
      type: "delete",
      url: `/orgs/${orgId}`,
      requestBody: {},
    }),
    getOrgOptions: (orgId) => ({
      type: "options",
      url: `/orgs/${orgId}`,
      requestBody: {},
    }),
    getOrgMachinesOptions: (orgId) => ({
      type: "options",
      url: `/orgs/${orgId}/machines`,
      requestBody: {},
    }),
    getOrgMachineOptions: (orgId, machineId) => ({
      type: "options",
      url: `/orgs/${orgId}/machines/${machineId}`,
      requestBody: {},
    }),
    getMachineAccess: (orgId, machineId) => ({
      type: "get",
      url: `/orgs/${orgId}/machines/${machineId}/access`,
      requestBody: {},
    }),
    postOrgMachineAccess: ({
      orgId,
      machineId,
      accessEnabled,
      blockSchedulingEnabled,
      prioritySchedulingEnabled,
      defaultPriority,
    }) => ({
      type: "post",
      url: `/orgs/${orgId}/machines/${machineId}/access`,
      requestBody: {
        access_enabled: accessEnabled,
        block_scheduling_enabled: blockSchedulingEnabled,
        priority_scheduling_enabled: prioritySchedulingEnabled,
        default_priority: defaultPriority,
      },
    }),
    deleteOrgMachineAccess: ({ orgId, machineId }) => ({
      type: "delete",
      url: `/orgs/${orgId}/machines/${machineId}/access`,
      requestBody: {},
    }),
    patchOrgMachineAccess: ({
      orgId,
      machineId,
      accessEnabled,
      blockSchedulingEnabled,
      prioritySchedulingEnabled,
      defaultPriority,
    }) => ({
      type: "patch",
      url: `/orgs/${orgId}/machines/${machineId}/access`,
      requestBody: {
        access_enabled: accessEnabled,
        block_scheduling_enabled: blockSchedulingEnabled,
        priority_scheduling_enabled: prioritySchedulingEnabled,
        default_priority: defaultPriority,
      },
    }),
    getOrgMachineAccessList: ({ orgId }) => ({
      type: "get",
      url: `/orgs/${orgId}/machines/access`,
      requestBody: {},
    }),
    getMachineUsage: (orgId, start, end) => ({
      type: "get",
      url: `/orgs/${orgId}/machines/usage?start=${start}&end=${end}`,
      requestBody: {},
    }),
    getOrgUsersOptions: (orgId) => ({
      type: "options",
      url: `/orgs/${orgId}/users`,
      requestBody: {},
    }),
    getOrgUsers: ({ orgId }) => ({
      type: "get",
      url: `/orgs/${orgId}/users`,
      requestBody: {},
    }),
    postOrgUser: ({ orgId, userId, isOrgAdmin, userName, userEmail }) => ({
      type: "post",
      url: `/orgs/${orgId}/users`,
      requestBody: {
        user_id: `${userId}`,
        is_org_admin: `${isOrgAdmin || false}`,
        user_name: `${userName}`,
        user_email: `${userEmail}`,
      },
    }),
    getOrgUserOptions: (orgId, userId) => ({
      type: "options",
      url: `/orgs/${orgId}/users/${userId}`,
      requestBody: {},
    }),
    deleteOrgUser: ({ orgId, userId }) => ({
      type: "delete",
      url: `/orgs/${orgId}/users/${userId}`,
      requestBody: {},
    }),
    putOrgUser: ({ orgId, userId, isOrgAdmin }) => ({
      type: "put",
      url: `/orgs/${orgId}/users/${userId}`,
      requestBody: {
        user_id: `${userId}`,
        is_org_admin: isOrgAdmin,
      },
    }),
    deleteOrgUsersAdmin: (orgId, userId) => ({
      type: "delete",
      url: `/orgs/${orgId}/users/${userId}/admin`,
      requestBody: {},
    }),
    getProjectsList: ({ orgId }) => ({
      type: "get",
      url: `/orgs/${orgId}/projects`,
      requestBody: {},
    }),
    getProjectsListOptions: ({ orgId }) => ({
      type: "options",
      url: `/orgs/${orgId}/projects`,
      requestBody: {},
    }),
    postProjectsList: ({ orgId, projectName }) => ({
      type: "post",
      url: `/orgs/${orgId}/projects`,
      requestBody: { name: `${projectName}` },
    }),
    deleteProjectsList: (id) => ({
      type: "delete",
      url: `/orgs/${id}/projects`,
      requestBody: {},
    }),
    deleteProject: ({ orgId, projectId }) => ({
      type: "delete",
      url: `/orgs/${orgId}/projects/${projectId}`,
      requestBody: {},
    }),
    getProjectOptions: ({ orgId, projectId }) => ({
      type: "options",
      url: `/orgs/${orgId}/projects/${projectId}`,
      requestBody: {},
    }),
    patchProjectDetails: ({ orgId, projectId, projectName }) => ({
      type: "patch",
      url: `/orgs/${orgId}/projects/${projectId}`,
      requestBody: { name: projectName },
    }),
    getOrgProjectUsersOptions: (orgId, projectId) => ({
      type: "options",
      url: `/orgs/${orgId}/projects/${projectId}/users`,
      requestBody: {},
    }),
    getProjectUsersList: ({ orgId, projectId }) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/users`,
      requestBody: {},
    }),
    getOrgProjectUserOptions: ({ orgId, projectId, userId }) => ({
      type: "options",
      url: `/orgs/${orgId}/projects/${projectId}/users/${userId}`,
      requestBody: {},
    }),
    postProjectUser: ({ orgId, projectId, userId }) => ({
      type: "post",
      url: `/orgs/${orgId}/projects/${projectId}/users`,
      requestBody: {
        user_id: `${userId}`,
      },
    }),
    deleteProjectUser: ({ orgId, projectId, userId }) => ({
      type: "delete",
      url: `/orgs/${orgId}/projects/${projectId}/users/${userId}`,
      requestBody: {},
    }),
    getOrgProjectMachinesOptions: (orgId, projectId) => ({
      type: "options",
      url: `/orgs/${orgId}/projects/${projectId}/machines`,
      requestBody: {},
    }),
    getOrgProjectMachine: (orgId, projectId, machineId) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}`,
      requestBody: {},
    }),
    getOrgProjectMachineJobsList: (orgId, projectId, machineId) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs`,
      requestBody: {},
    }),
    postOrgProjectMachineJobsList: (orgId, projectId, machineId) => ({
      type: "post",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs`,
      requestBody: {},
    }),
    putOrgProjectMachineJobCancel: ({ orgId, projectId, machineId, jobId }) => ({
      type: "put",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs/${jobId}/status/cancel`,
      requestBody: {},
    }),
    putOrgProjectMachineJobCancelAll: ({ orgId, projectId, machineId }) => ({
      type: "put",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs/cancel`,
      requestBody: {},
    }),
    getOrgProjectMachineJobStatus: (orgId, projectId, machineId, jobId) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs/${jobId}/status`,
      requestBody: {},
    }),
    getOrgProjectMachineJob: ({ orgId, projectId, machineId, jobId }) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs/${jobId}`,
      requestBody: {},
    }),
    getOrgProjectMachineAccess: (orgId, projectId, machineId) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/access`,
      requestBody: {},
    }),
    postOrgProjectMachineAccess: ({
      orgId,
      projectId,
      machineId,
      accessEnabled,
      blockSchedulingEnabled,
      prioritySchedulingEnabled,
      defaultPriority,
    }) => ({
      type: "post",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/access`,
      requestBody: {
        access_enabled: accessEnabled,
        block_scheduling_enabled: blockSchedulingEnabled,
        priority_scheduling_enabled: prioritySchedulingEnabled,
        default_priority: defaultPriority,
      },
    }),
    deleteOrgProjectMachineAccess: ({ orgId, projectId, machineId }) => ({
      type: "delete",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/access`,
      requestBody: {},
    }),
    patchOrgProjectMachineAccess: ({
      orgId,
      projectId,
      machineId,
      accessEnabled,
      blockSchedulingEnabled,
      prioritySchedulingEnabled,
      defaultPriority,
    }) => ({
      type: "patch",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/access`,
      requestBody: {
        access_enabled: accessEnabled,
        block_scheduling_enabled: blockSchedulingEnabled,
        priority_scheduling_enabled: prioritySchedulingEnabled,
        default_priority: defaultPriority,
      },
    }),
    getOrgProjectMachinesAccessList: ({ orgId, projectId }) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/access`,
      requestBody: {},
    }),
    getOrgProjectMachinesScheduleOptions: ({ orgId, projectId, machineId }) => ({
      type: "options",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/schedule`,
      requestBody: {},
    }),
    getOrgProjectMachinesScheduleOpen: (
      orgId,
      projectId,
      machineId,
      start,
      end
    ) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/schedule/open?start=${start}&end=${end}`,
      requestBody: {},
    }),
    getOrgProjectMachinesScheduleReservations: (
      orgId,
      projectId,
      machineId,
      start,
      end
    ) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/schedule/reservations?start=${start}&end=${end}`,
      requestBody: {},
    }),
    postOrgProjectMachinesScheduleReservation: ({
      orgId,
      projectId,
      machineId,
      resvStart,
      resvEnd,
      resvMessage = "",
    }) => ({
      type: "post",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/schedule/reservations`,
      requestBody: {
        start: `${resvStart}`,
        end: `${resvEnd}`,
        message: `${resvMessage}`,
      },
    }),
    deleteOrgProjectMachinesScheduleReservation: ({
      orgId,
      projectId,
      machineId,
      scheduleId,
    }) => ({
      type: "delete",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/schedule/reservations/${scheduleId}`,
      requestBody: {},
    }),
    patchOrgProjectMachinesScheduleReservation: (
      orgId,
      projectId,
      machineId,
      reservationId
    ) => ({
      type: "patch",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/schedule/reservations/${reservationId}`,
      requestBody: {},
    }),
    getMachinesList: {
      type: "get",
      url: "/machines",
      requestBody: {},
    },
    postMachine: (name) => ({
      type: "patch",
      url: "/machines",
      requestBody: {
        name: `${name}`,
      },
    }),
    getMachineOptions: {
      type: "options",
      url: "/machines",
      requestBody: {},
    },
    getMachineStatus: (machineId) => ({
      type: "get",
      url: `/machines/${machineId}/status`,
      requestBody: {},
      optionsRoute: "getMachineOptions",
    }),
    postMachineStatus: (
      // This needs to be fleshed out more the request body in swagger looks more like a response
      machineId
    ) => ({
      type: "get",
      url: `/machines/${machineId}/status`,
      requestBody: {},
    }),
    getMachineOpen: ({ machineId, start, end, noConflicts = true }) => ({
      type: "get",
      url: `/machines/${machineId}/schedule/open?start=${start}&end=${end}&no_conflicts=${noConflicts}`,
      requestBody: {},
    }),
    getDowntimeList: ({ machineId, start, end }) => ({
      type: "get",
      url: `/machines/${machineId}/schedule/downtime_blocks?start=${start}&end=${end}`,
      requestBody: {},
    }),
    postDowntimeBlock: ({ machineId, blockStart, blockEnd, blockMessage = "" }) => ({
      type: "post",
      url: `/machines/${machineId}/schedule/downtime_blocks`,
      requestBody: {
        start: `${blockStart}`,
        end: `${blockEnd}`,
        message: blockMessage,
      },
    }),
    deleteDowntimeBlock: ({ machineId, blockId }) => ({
      type: "delete",
      url: `/machines/${machineId}/schedule/downtime_blocks/${blockId}`,
      requestBody: {},
    }),
    patchDowntimeBlock: (machineId, blockId) => ({
      type: "patch",
      url: `/machines/${machineId}/schedule/downtime_blocks/${blockId}`,
      requestBody: {},
    }),
    getDowntimeOverlap: (machineId, start, end) => ({
      type: "get",
      url: `/machines/${machineId}/schedule/downtime_block_check_overlap?start=${start}&end=${end}`,
      requestBody: {},
    }),
    getMachineJobs: (machineId) => ({
      type: "get",
      url: `/machines/${machineId}/jobs/next`,
      requestBody: {},
    }),
    postMachineJobOutput: (machineId, jobId) => ({
      type: "get",
      url: `/machines/${machineId}/jobs/${jobId}/output`,
      requestBody: {},
    }),
    getMachineJobStatus: (machineId, jobId) => ({
      type: "get",
      url: `/machines/${machineId}/jobs/${jobId}/status`,
      requestBody: {},
    }),
    putMachineJobStatusFail: (machineId, jobId) => ({
      type: "put",
      url: `/machines/${machineId}/jobs/${jobId}/status/fail`,
      requestBody: {},
    }),
    getProjectMachineAccess: ({ orgId, projectId }) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/access`,
      requestBody: {},
    }),
    getProjectMachineOptions: ({ orgId, projectId, machineId }) => ({
      type: "options",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}`,
      requestBody: {},
    }),
    getProjectMachineJobs: ({
      orgId,
      projectId,
      machineId,
      pageSize = "40",
      pageToken = "",
    }) => ({
      type: "get",
      url: `/orgs/${orgId}/projects/${projectId}/machines/${machineId}/jobs?page_size=${pageSize}&page_token=${pageToken}`,
      requestBody: {},
    }),
  },
};

export default apiSpec;
