import React from "react";
import { HiddenInput, FormButtonsDelete } from "components/common/form";
import { Message } from "semantic-ui-react";

const OrgUserDelete = ({ data, ...props }) => {
  const formData = {
    userId: data?.id,
    userName: data?.name,
  };

  return <UserDeleteForm formData={formData} {...props} />;
};

const UserDeleteForm = ({ formControl, formData }) => (
  <>
    <Message negative data-test="delete_confirm_message">
      Remove {formData?.userName} from Organization?
    </Message>
    <HiddenInput fieldId="userId" fieldName="userId" setVal={formData?.userId} />
    <FormButtonsDelete formControl={formControl} />
  </>
);

export default OrgUserDelete;
