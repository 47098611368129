import { atom } from "recoil";

const scheduleSelectedState = atom({
  key: "scheduleSelectedState",
  default: {
    id: null,
    message: null,
    blockStart: null,
    blockEnd: null,
  },
});

export default scheduleSelectedState;
