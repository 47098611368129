import { toast } from "react-toastify";

const CONSOLE_ENABLED = false;

const logInfo = /* istanbul ignore next */ (info, displayMsg) => {
  CONSOLE_ENABLED && console.log(info); // eslint-disable-line

  return (
    process.env.NODE_ENV === "development" && displayMsg && toast.warn(displayMsg)
  );
};

const logWarn = (warning, displayMsg) => {
  CONSOLE_ENABLED && /* istanbul ignore next */ console.warn(warning); // eslint-disable-line
  return (
    process.env.NODE_ENV === "development" && displayMsg && toast.warn(displayMsg)
  );
};

const logError = (error, displayMsg = error) => {
  CONSOLE_ENABLED && /* istanbul ignore next */ console.error(error); // eslint-disable-line
  return process.env.NODE_ENV === "development" && toast.error(displayMsg);
};

const log = (logItems) => {
  logItems.forEach((item) => {
    switch (item.type) {
      case "warn":
        logWarn(item.consoleMsg, item.displayMsg);
        break;

      case "error":
        logError(item.consoleMsg, item.displayMsg);
        break;
      /* istanbul ignore next */
      default:
        logInfo(item.consoleMsg, item.displayMsg);
    }
  });
};

const logger = {
  log,
  logInfo,
  logWarn,
  logError,
};

export default logger;
