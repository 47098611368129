import React from "react";
import { Tab, Header } from "semantic-ui-react";

export const PageHeader = ({ title, sub, children }) => (
  <div className="page-header" data-test="layout-page-header">
    <Header className="page-header-title">
      {title}
      {sub && <Header.Subheader>{sub}</Header.Subheader>}
    </Header>
    {children}
  </div>
);

export const PageHeaderTabs = ({
  panes,
  handleTabChange,
  activeIndex = /* istanbul ignore next */ 0,
}) => (
  <Tab
    data-test="page-header-tab"
    className="page-header-tab"
    onTabChange={handleTabChange}
    activeIndex={activeIndex}
    panes={panes}
    menu={{ secondary: true, pointing: true }}
    renderActiveOnly
  />
);
