import React from "react";
import { FormButtons, StrInput } from "components/common/form";
import MachineAccessToggles from "./MachineAccessToggles";

const MachineAccessEdit = ({ data, ...props }) => {
  const formData = {
    machineId: data?.machine_id,
    machineName: data?.machine_name,
    accessEnabled: data?.access?.access_enabled,
    blockSchedulingEnabled: data?.access?.block_scheduling_enabled,
    prioritySchedulingEnabled: data?.access?.priority_scheduling_enabled,
    defaultPriority: data?.access?.default_priority,
  };

  return <MachineAccessEditForm formData={formData} {...props} />;
};

const MachineAccessEditForm = ({ formControl, formData }) => (
  <>
    <StrInput
      fieldId="machineName"
      fieldName="machineName"
      label="Machine Name"
      readOnly={formData?.machineName}
      setVal={formData?.machineName}
    />
    <StrInput
      fieldId="machineId"
      fieldName="machineId"
      label="Machine Id"
      readOnly={formData?.machineId}
      setVal={formData?.machineId}
    />
    <MachineAccessToggles data={formData} />
    <FormButtons formControl={formControl} />
  </>
);

export default MachineAccessEdit;
