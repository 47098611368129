import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { formActionState } from "store/formState";
import { getOrgUsersList, userSelectedState } from "store";
import { ListHeading } from "components/common/ui";
import { FormModal, FormModalButton } from "components/common/form";
import { PageContent, EditDrawer } from "components/common/layout";
import { UsersTable, UserDetail } from "components/users";
import formConfigLib from "config/formConfig";
import OrgUsersForm from "components/orgs/users";
import { WithPerms } from "components/common/util";
import { ROUTES } from "services/httpService";

const OrgUsers = () => {
  const {
    params: { orgId },
  } = useRouteMatch();
  const { orgUsers, getOrgUserDetails } = useRecoilValue(getOrgUsersList({ orgId }));
  const currentUser = useRecoilValue(userSelectedState);
  const currentUserDetails = getOrgUserDetails(currentUser);

  const formConfig = formConfigLib.orgUserForm;
  const formAction = useRecoilValue(formActionState(formConfig.formName));
  const parentType = "org";

  return (
    <>
      <PageContent>
        <ListHeading title="Organization Users">
          <WithPerms perm="add" route={ROUTES.getOrgUsersOptions(orgId)}>
            <FormModalButton
              modalAtom={formConfig.formName}
              testTarget="user_add_btn"
              btnText="Add User"
              formAction="add"
            />
          </WithPerms>
        </ListHeading>
        <UsersTable data={orgUsers} />
      </PageContent>
      <EditDrawer>
        <UserDetail user={currentUserDetails} parentType={parentType}>
          <WithPerms
            perm="edit"
            route={ROUTES.getOrgUserOptions(orgId, currentUser)}
          >
            <FormModalButton
              basic
              modalAtom={formConfig.formName}
              testTarget="user_edit_btn"
              btnText="Edit"
              formAction="edit"
            />
          </WithPerms>
          <WithPerms
            perm="delete"
            route={ROUTES.getOrgUserOptions(orgId, currentUser)}
          >
            <FormModalButton
              basic
              modalAtom={formConfig.formName}
              testTarget="user_delete_btn"
              btnText="Remove"
              formAction="delete"
            />
          </WithPerms>
        </UserDetail>
      </EditDrawer>
      <FormModal modalAtom={formConfig.formName} title="User" size="mini">
        <OrgUsersForm
          formAction={formAction}
          formConfig={formConfig}
          orgId={orgId}
        />
      </FormModal>
    </>
  );
};

export default OrgUsers;
