import React from "react";

export const PageContentWrapper = ({ children }) => (
  <div className="page-content-wrapper" data-test="layout-page-content-wrap">
    {children}
  </div>
);

export const PageContent = ({ children }) => (
  <div className="page-content" data-test="layout-page-content">
    {children}
  </div>
);
