import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  modalOpenState,
  editDrawerOpenState,
  getOrgMachineAccessList,
  machineSelectedState,
} from "store";
import { Form } from "semantic-ui-react";
import useForm from "services/useForm";
import { HiddenInput } from "components/common/form";
import MachineAccessAdd from "./MachineAccessAdd";
import MachineAccessEdit from "./MachineAccessEdit";
import MachineAccessDelete from "./MachineAccessDelete";

const OrgMachineAccessForm = ({ orgId, formAction, formConfig }) => {
  const { getMachineDetails } = useRecoilValue(getOrgMachineAccessList({ orgId }));
  const selectedMachine = useRecoilValue(machineSelectedState);
  const currentMachine = getMachineDetails(selectedMachine);

  const setModalState = useSetRecoilState(modalOpenState(formConfig.formName));
  const setDrawerState = useSetRecoilState(editDrawerOpenState);

  const handeDrawerChange = () => formAction !== "edit" && setDrawerState(false);

  const postSubmitCleanup = () => {
    setModalState(false);
    handeDrawerChange();
  };

  const { submit, reset, isInvalid } = useForm({
    formConfig: formConfig[formAction],
    postSubmitCleanup,
  });

  const formControl = {
    formName: formConfig.formName,
    formAction,
    submit,
    reset,
    isInvalid,
  };

  const FormFields = {
    add: <MachineAccessAdd formControl={formControl} orgId={orgId} />,
    edit: <MachineAccessEdit formControl={formControl} data={currentMachine} />,
    delete: <MachineAccessDelete formControl={formControl} data={currentMachine} />,
  };

  return (
    <Form>
      {FormFields[formAction]}
      <HiddenInput fieldId="orgId" fieldName="orgId" setVal={orgId} />
    </Form>
  );
};

export default OrgMachineAccessForm;
