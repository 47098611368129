import React from "react";
import { useRecoilValue } from "recoil";
import { getOrgList } from "store";
import { Link } from "react-router-dom";
import { WithSuspense } from "components/common/util";
import { TableMain } from "components/common/table";
import GenerateTableConfig from "components/common/table/tableInterface";

const OrgListItem = ({ item }) => (
  <Link to={(location) => `${location.pathname}/${item.id}`}>{item.name}</Link>
);

const OrgsListCore = () => {
  const labels = [
    { label: "Organization Name", value: "name" },
    { label: "ID", value: "id" },
  ];

  const { orgs } = useRecoilValue(getOrgList);

  const data = orgs.map((item) => ({
    id: item.id,
    name: <OrgListItem key={item.id} item={item} />,
  }));

  return (
    <TableMain
      testTarget="orgs_list"
      tableConfig={GenerateTableConfig(data, labels)}
    />
  );
};

const OrgsList = () => (
  <WithSuspense>
    <OrgsListCore />
  </WithSuspense>
);

export default OrgsList;
