import React from "react";
import { useRecoilState } from "recoil";
import { editDrawerOpenState, userSelectedState } from "store";
import { WithSuspense } from "components/common/util";
import { TableMain } from "components/common/table";

const tableHeaders = [
  { label: "User Name", value: "name" },
  { label: "Email", value: "email" },
];

const UsersTable = ({ data }) => {
  const [selectedUser, setSelectedUser] = useRecoilState(userSelectedState);
  const [drawerState, setDrawerState] = useRecoilState(editDrawerOpenState);

  const handleDrawerChange = () => !drawerState && setDrawerState(true);
  const handleSelectionChange = (id) => id !== selectedUser && setSelectedUser(id);

  const openDrawer = (id) => {
    handleSelectionChange(id);
    handleDrawerChange();
  };

  const tableConfig = {
    data,
    selectedId: selectedUser,
    clickHandler: (id) => openDrawer(id),
    headers: tableHeaders,
  };

  return (
    <WithSuspense>
      <TableMain
        testTarget="users_list"
        tableConfig={tableConfig}
        tableClass="users-list"
      />
    </WithSuspense>
  );
};

export default UsersTable;
