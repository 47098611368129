import React from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Button } from "semantic-ui-react";

const CookieConsentBanner = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept"
    expires={150}
    enableDeclineButton
    declineButtonText="Decline"
    disableButtonStyles
    ButtonComponent={Button}
    buttonClasses="ui blue tiny button cookie-button"
    declineButtonClasses="ui red tiny button cookie-button"
    customContainerAttributes={{ "data-test": "cookie-banner" }}
    customContentAttributes={{ "data-test": "cookie-banner-content" }}
  >
    This website uses cookies to enhance the user experience, as specified in our{" "}
    <Link data-test="cookies-link" className="cookie-policy-link" to="/cookies">
      cookie policy
    </Link>
    .
  </CookieConsent>
);

export default CookieConsentBanner;
