import React from "react";
import { Loader } from "semantic-ui-react";

const Loading = () => (
  <div className="loading-pane">
    <Loader active inline="centered">
      Loading
    </Loader>
  </div>
);

export default Loading;
