import { selectorFamily } from "recoil";
import { makeRequest } from "services/httpService";

const getOptions = selectorFamily({
  key: "getOptions",
  get:
    ({ route }) =>
    async () => {
      const res = await makeRequest(route);
      return res.data ? res.data.permissions : /* istanbul ignore next */ [];
    },
});

export default getOptions;
