/**
 * A collection of recoil atom & selector families that generate and manage
 * state objects used by 'useForm' as a middleware for common form actions.
 */

import { atomFamily, selectorFamily } from "recoil";
import * as Yup from "yup";
import { validationSchema } from "config/formConfig";

/**
 * Calls 'yup' to validate the field using 'validationSchema'.
 */
const validateFormValues = ({ fieldId, value, compareFields }) => {
  try {
    Yup.reach(validationSchema, fieldId).validateSync(value, {
      context: { compareFields },
    });
  } catch (e) {
    return {
      errType: e.name,
      errMessage: e.errors ?? /* istanbul ignore next */ [
        "Unknown Validation Error",
      ],
    };
  }
  return null;
};

/**
 * Manages form action state (add, edit, delete) that is used to sync
 * from controllers and form container components (e.g. "FormModal")
 */
export const formActionState = atomFamily({
  key: "formActionState",
  default: "add",
});

/**
 * Generates an atom to hold the individual state of a form field.
 */
export const fieldState = atomFamily({
  key: "fieldState",
  default: {},
});

/**
 * Generates a selector to operate on it's matching 'fieldState' atom.
 * Provides reducer-type methods to perform actions on an atom state, similar to redux dispatch.
 * Handles getting, setting and validating an individual form field value.
 */
export const fieldStateSelector = selectorFamily({
  key: "fieldStateSelector",
  get:
    (id) =>
    ({ get }) =>
      get(fieldState(id)),
  set:
    (id) =>
    ({ set }, { type, value, compareFields }) => {
      // validate on blur, otherwise update value
      if (type === "blur") {
        set(fieldState(id), {
          value,
          errors: validateFormValues({ fieldId: id, value, compareFields }),
        });
        return;
      }
      set(fieldState(id), {
        value,
        errors: null,
      });
    },
});

/**
 * Throws a specific error if the 'fields' array hasn't been defined/populated
 */
const checkFields = (fields) => {
  /* istanbul ignore if */
  if (!fields) {
    throw new Error(
      "Fields not found in config/formConfig.js. Please update the config file."
    );
  }
};

/**
 * Handles getting and resetting the collection of ALL form
 * field values stored in the 'fieldState' atom family.
 */
export const formDataSelector = selectorFamily({
  key: "formDataSelector",
  get:
    ({ fields }) =>
    ({ get }) => {
      checkFields(fields);
      return fields.reduce((acc, field) => {
        const fieldData = get(fieldState(field));
        return { ...acc, [field]: fieldData.value };
      }, {});
    },
  set:
    ({ fields }) =>
    ({ reset }) => {
      fields.forEach((field) => {
        reset(fieldState(field));
      });
    },
});

/**
 * Determines if any of the fields currently have an error state set by
 * 'validateFormValues', in order to enable/disable submitting the form
 * based on validation rules
 */
export const validateForm = selectorFamily({
  key: "validateForm",
  get:
    ({ fields }) =>
    ({ get }) => {
      checkFields(fields);
      return fields.reduce((acc, field) => {
        const fieldData = get(fieldState(field));
        // check if value is required && empty
        const isRequired =
          validationSchema.fields[field]?.spec?.presence === "required";
        if (
          fieldData.errors ||
          (!fieldData.value && isRequired) ||
          (fieldData.value === "" && isRequired)
        ) {
          return true;
        }
        return acc;
      }, false);
    },
});

/**
 * Counter which is incremented on successful form submission.
 * Triggers a refetch to perform another GET after a POST/PATCH/DELETE is successful.
 * Used inside of each resources' GET selector to subscribe the selector to the update of this value
 */
export const refetchIdFamily = atomFamily({
  key: "refetchId",
  default: 0,
});
