import React from "react";
import { Button } from "semantic-ui-react";
import { StrInput } from "components/common/form";

const OrgAdd = ({ formControl }) => (
  <>
    <StrInput fieldId="orgName" fieldName="orgName" label="Org Name" />
    <Button
      data-test="org_add_submit_button"
      content="Submit"
      size="tiny"
      primary
      disabled={formControl.isInvalid}
      onClick={formControl.submit}
    />
    <Button
      data-test="org_add_cancel_button"
      size="tiny"
      onClick={formControl.reset}
    >
      Cancel
    </Button>
  </>
);

export default OrgAdd;
