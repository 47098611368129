import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getOrgMachineAccessList, machineSelectedState } from "store";
import { formActionState } from "store/formState";
import { PageContent, EditDrawer } from "components/common/layout";
import { ListHeading } from "components/common/ui";
import { FormModal, FormModalButton } from "components/common/form";
import { MachineAccessList, MachineAccessDetails } from "components/machines/access";
import formConfigLib from "config/formConfig";
import OrgMachineAccessForm from "components/orgs/machines";
import { WithPerms } from "components/common/util";
import { ROUTES } from "services/httpService";

const OrgMachineAccess = () => {
  const {
    params: { orgId },
    url,
  } = useRouteMatch();
  const { machines, getMachineDetails } = useRecoilValue(
    getOrgMachineAccessList({ orgId })
  );

  const currentMachine = useRecoilValue(machineSelectedState);

  const formConfig = formConfigLib.orgMachineAccessForm;
  const formAction = useRecoilValue(formActionState(formConfig.formName));

  const listTableHeaders = [
    { label: "Machine Name", value: "name" },
    { label: "Access Enabled", value: "enabled" },
    { label: "Block Schedule", value: "block" },
    { label: "Priority Schedule", value: "priority" },
    { label: "Default Priority", value: "default_priority" },
  ];

  return (
    <>
      <PageContent>
        <ListHeading title="Machine Access">
          <WithPerms perm="add" route={ROUTES.getOrgMachinesOptions(orgId)}>
            <FormModalButton
              modalAtom={formConfig.formName}
              testTarget="machine_add_btn"
              btnText="Add Machine Access"
              formAction="add"
            />
          </WithPerms>
        </ListHeading>
        <MachineAccessList
          data={machines}
          currPath={url}
          tableHeaders={listTableHeaders}
        />
      </PageContent>
      <EditDrawer>
        <MachineAccessDetails machine={getMachineDetails(currentMachine)}>
          <WithPerms
            perm="edit"
            route={ROUTES.getOrgMachineOptions(orgId, currentMachine)}
          >
            <FormModalButton
              basic
              modalAtom={formConfig.formName}
              testTarget="machine_edit_btn"
              btnText="Edit"
              formAction="edit"
            />
          </WithPerms>
          <WithPerms
            perm="delete"
            route={ROUTES.getOrgMachineOptions(orgId, currentMachine)}
          >
            <FormModalButton
              basic
              modalAtom={formConfig.formName}
              testTarget="machine_delete_btn"
              btnText="Delete"
              formAction="delete"
            />
          </WithPerms>
        </MachineAccessDetails>
      </EditDrawer>
      <FormModal modalAtom={formConfig.formName} title="Machine Access" size="mini">
        <OrgMachineAccessForm
          formAction={formAction}
          formConfig={formConfig}
          orgId={orgId}
        />
      </FormModal>
    </>
  );
};

export default OrgMachineAccess;
