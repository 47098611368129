import React from "react";
import { useRecoilValue } from "recoil";
import { formActionState } from "store/formState";
import formConfigLib from "config/formConfig";
import { WithPerms } from "components/common/util";
import { OrgsList } from "components/orgs";
import OrgForm from "components/orgs/form";
import { ROUTES } from "services/httpService";
import {
  PageHeader,
  PageContentWrapper,
  PageContent,
} from "components/common/layout";

import { FormModal, FormModalButton } from "components/common/form";
import Layout from "components/common/layout/MainLayout";

const Orgs = () => {
  const formConfig = formConfigLib.orgForm;
  const formAction = useRecoilValue(formActionState(formConfig.formName));

  return (
    <Layout>
      <PageHeader title="Organizations">
        <WithPerms perm="add" route={ROUTES.getOrgListOptions}>
          <FormModalButton
            modalAtom={formConfig.formName}
            testTarget="add_org_button"
            btnText="Add Org"
            formAction="add"
          />
        </WithPerms>
      </PageHeader>
      <PageContentWrapper>
        <PageContent>
          <OrgsList />
        </PageContent>
        <FormModal modalAtom={formConfig.formName} title="Org" size="mini">
          <OrgForm formAction={formAction} formConfig={formConfig} />
        </FormModal>
      </PageContentWrapper>
    </Layout>
  );
};

export default Orgs;
