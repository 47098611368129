import React from "react";
import { Header } from "semantic-ui-react";
import { StatusIcon } from "components/common/ui";

const UserDetail = ({ user, parentType, children }) =>
  user && user.name ? (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr" }}>
        <Header as="h3">User Details</Header>
      </div>
      <Header as="h5" color="grey">
        Name
      </Header>
      {user.name}
      <Header as="h5" color="grey">
        Email
      </Header>
      {user.email}

      {parentType === "global" && (
        <>
          <Header as="h5" color="grey">
            Group Associations
          </Header>
          <ul className="key-value-container">
            <li className="key-value">User is CQ Admin</li>
            <li className="key-value">
              <StatusIcon id="is_admin_status" value={user?.groups.cq_admin} />
            </li>
            <li className="key-value">User is Operator</li>
            <li className="key-value">
              <StatusIcon value={user?.groups.cq_operator} />
            </li>
            <li className="key-value">User is Accounting</li>
            <li className="key-value">
              <StatusIcon value={user?.groups.cq_accounting} />
            </li>
          </ul>
        </>
      )}
      {parentType === "org" && (
        <>
          <Header as="h5" color="grey">
            User Type
          </Header>
          <ul className="key-value-container">
            <li className="key-value">User is an Org Admin</li>
            <li className="key-value">
              <StatusIcon value={user?.is_org_admin} />
            </li>
          </ul>
        </>
      )}
      <div>{children}</div>
    </>
  ) : (
    <div />
  );

export default UserDetail;
