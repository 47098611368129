import React from "react";
import { useRecoilValue } from "recoil";
import { getOrgMachineAccessList, getMachinesList } from "store";
import { SelectInput, FormButtons } from "components/common/form";
import { Message } from "semantic-ui-react";
import MachineAccessToggles from "./MachineAccessToggles";

const MachineAccessAdd = ({ orgId, ...props }) => {
  const { machines } = useRecoilValue(getMachinesList);
  const { getMachineDetails } = useRecoilValue(getOrgMachineAccessList({ orgId }));

  const selectOptions = machines.map((u) => ({
    key: u.id,
    text: u.name,
    value: u.id,
  }));

  const filteredOptions = selectOptions.filter(
    (item) => !getMachineDetails(item.value)
  );

  return <MachineAccessAddForm filteredOptions={filteredOptions} {...props} />;
};

export const MachineAccessAddForm = ({ formControl, filteredOptions }) => (
  <>
    {!filteredOptions.length ? (
      <Message
        data-test="no-machines-message"
        info
        content="There are no more machines available to be added."
      />
    ) : (
      <>
        <SelectInput
          fieldId="machineId"
          fieldName="machineId"
          label="Machine"
          options={filteredOptions}
          data-test="machine-access-select"
        />
        <MachineAccessToggles />
        <FormButtons formControl={formControl} />
      </>
    )}
  </>
);

export default MachineAccessAdd;
