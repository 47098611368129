import React from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { modalOpenState } from "store";
import { formActionState } from "store/formState";
import { Modal, Button, Icon } from "semantic-ui-react";

export const FormModalButton = ({
  modalAtom,
  testTarget,
  btnText,
  formAction = /* istanbul ignore next */ "add",
  ...props
}) => {
  const setModalState = useSetRecoilState(modalOpenState(modalAtom));
  const setFormState = useSetRecoilState(formActionState(modalAtom));

  const handleClick = () => {
    setFormState(formAction);
    setModalState(true);
  };

  const formActionItems = {
    add: {
      icon: <Icon style={{ paddingRight: "1.5em" }} name="plus" />,
      color: "blue",
    },
    edit: {
      icon: <Icon style={{ paddingRight: "1.5em" }} name="edit" />,
      color: "blue",
    },
    delete: {
      icon: <Icon style={{ paddingRight: "1.5em" }} name="delete" />,
      color: "red",
    },
    cancel: {
      icon: <Icon style={{ paddingRight: "1.5em" }} name="delete" />,
      color: "red",
    },
    cancel_all: {
      icon: <Icon style={{ paddingRight: "1.5em" }} name="delete" />,
      color: "red",
    },
  };

  return (
    <Button
      data-test={testTarget}
      icon
      color={formActionItems[formAction].color}
      size="tiny"
      onClick={handleClick}
      {...props}
    >
      {formActionItems[formAction].icon}
      {btnText}
    </Button>
  );
};

export const FormModal = ({ modalAtom, title, size, noPad, children }) => {
  const [modalState, setModalState] = useRecoilState(modalOpenState(modalAtom));
  const formAction = useRecoilValue(formActionState(modalAtom));

  const getHeaderText = () => {
    const q = formAction
      .split("_")
      .reduce((acc, c) => `${acc}${c[0].toUpperCase()}${c.substring(1)} `, "")
      .trim();

    return `${q} ${title}`;
  };

  return (
    <Modal
      data-test={modalAtom}
      size={size}
      onClose={() => setModalState(false)}
      open={modalState}
    >
      <Modal.Header>{getHeaderText()}</Modal.Header>
      {noPad ? (
        children
      ) : (
        <Modal.Content>
          <Modal.Description>{children}</Modal.Description>
        </Modal.Content>
      )}
    </Modal>
  );
};

export default FormModal;
