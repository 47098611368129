import { useRecoilValue } from "recoil";
import { getOptions } from "store";

const usePermissions = ({ route }) => {
  const perms = useRecoilValue(getOptions({ route }));

  // get array of perms for the specified resource
  const getPerms = perms ?? [];

  // find if ANY perms exist for the specified resource, returns boolean
  const hasAnyPerms = getPerms.length || null;

  // find specific perm, returns boolean
  const findPerm = (perm) => getPerms.includes(perm);

  return { getPerms, hasAnyPerms, findPerm };
};

export default usePermissions;
