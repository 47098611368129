import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loading } from "components/common/ui";
import Orgs from "components/pages/Orgs";
import { RecoilRoot } from "recoil";
import { CookieConsentBanner } from "components/legal";

const Users = lazy(() => import("components/pages/Users"));
const MachinesList = lazy(() => import("components/pages/Machines"));

const OrgDetails = lazy(() => import("components/pages/OrgDetails"));
const ProjectDetails = lazy(() => import("components/pages/ProjectDetails"));
const ProjectMachineDetails = lazy(() =>
  import("components/pages/ProjectMachineDetails")
);
const MachineDetailsPage = lazy(() => import("components/pages/MachineDetailsPage"));
const Cookies = lazy(() => import("components/pages/Cookies"));
const PageNotFound = lazy(() => import("components/pages/404"));

function App() {
  return (
    <RecoilRoot>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            path="/organizations/:orgId/projects/:projectId/machines/:projMachineId/status"
            exact
          >
            <ProjectMachineDetails tab="2" />
          </Route>
          <Route
            path="/organizations/:orgId/projects/:projectId/machines/:projMachineId/schedule"
            exact
          >
            <ProjectMachineDetails tab="1" />
          </Route>
          <Route
            path="/organizations/:orgId/projects/:projectId/machines/:projMachineId/jobs"
            exact
          >
            <ProjectMachineDetails tab="0" />
          </Route>
          <Route
            path="/organizations/:orgId/projects/:projectId/machines/:projMachineId"
            exact
          >
            <ProjectMachineDetails />
          </Route>
          <Route path="/organizations/:orgId/projects/:projectId/machines" exact>
            <ProjectDetails tab="0" />
          </Route>
          <Route path="/organizations/:orgId/projects/:projectId/users" exact>
            <ProjectDetails tab="1" />
          </Route>
          <Route path="/organizations/:orgId/projects/:projectId" exact>
            <ProjectDetails />
          </Route>
          <Route path="/organizations/:orgId/users" exact>
            <OrgDetails tab="2" />
          </Route>
          <Route path="/organizations/:orgId/machines" exact>
            <OrgDetails tab="1" />
          </Route>
          <Route path="/organizations/:orgId/projects" exact>
            <OrgDetails tab="0" />
          </Route>
          <Route path="/organizations/:orgId" exact>
            <OrgDetails />
          </Route>
          <Route path="/organizations" exact>
            <Orgs />
          </Route>
          <Route path="/machines/:machineId/schedule" exact>
            <MachineDetailsPage tab="1" />
          </Route>
          <Route path="/machines/:machineId/status" exact>
            <MachineDetailsPage tab="0" />
          </Route>
          <Route path="/machines/:machineId" exact>
            <MachineDetailsPage />
          </Route>
          <Route path="/machines" exact>
            <MachinesList />
          </Route>
          <Route path="/users" exact>
            <Users />
          </Route>
          <Route path="/cookies" exact>
            <Cookies />
          </Route>
          <Route path="/401" exact>
            <PageNotFound />
          </Route>
          <Route path="/" exact>
            <Redirect to="/organizations" />
          </Route>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </Suspense>
      <CookieConsentBanner />
      <ToastContainer limit={3} hideProgressBar pauseOnFocusLoss={false} />
    </RecoilRoot>
  );
}

export default App;
