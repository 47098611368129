import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { fieldStateSelector } from "store/formState";

export const HiddenInput = ({ fieldId, fieldName, setVal, ...props }) => {
  const [fieldState, setFieldState] = useRecoilState(fieldStateSelector(fieldId));

  useEffect(() => {
    /* istanbul ignore else */
    if (setVal) {
      setFieldState({ type: "setVal", value: setVal });
    }
  }, [setVal, setFieldState]);

  return (
    <input
      type="hidden"
      id={fieldId}
      name={fieldName}
      value={fieldState?.value ?? ""}
      {...props}
    />
  );
};

export default HiddenInput;
