import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { useRouteMatch, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  getOrgList,
  getProjectList,
  getProjectMachineAccess,
  getMachinesList,
} from "store";
import capitalize from "lodash/capitalize";
import truncate from "lodash/truncate";

const GetMachine = ({ val }) => {
  const { getMachineDetails } = useRecoilValue(getMachinesList);
  return getMachineDetails(val)?.name || "unknown";
};

const GetOrg = ({ val }) => {
  const { getOrgDetails } = useRecoilValue(getOrgList);
  return getOrgDetails(val)?.name || "unknown";
};

const GetProject = ({ val, orgId }) => {
  const { getProjectDetails } = useRecoilValue(getProjectList({ orgId }));
  return getProjectDetails(val)?.name || "unknown";
};

const GetProjectMachine = ({ val, orgId, projectId }) => {
  const { getMachineDetails } = useRecoilValue(
    getProjectMachineAccess({ orgId, projectId })
  );
  return getMachineDetails(val)?.machine_name || "unknown";
};

const useGetNames = (initData) => {
  const { orgId, projectId } = initData;

  const getNames = ({ params, c }) =>
    Object.entries(params).reduce((names, [key, val]) => {
      const dict = {
        orgId: () => <GetOrg val={val} />,
        projectId: () => <GetProject val={val} orgId={orgId} />,
        projMachineId: () => (
          <GetProjectMachine val={val} orgId={orgId} projectId={projectId} />
        ),
        machineId: () => <GetMachine val={val} />,
      };

      return val === c ? { [val]: dict[key]() } : names;
    }, {});

  return getNames;
};

const useBreadcrumbs = ({ route }) => {
  const getNames = useGetNames(route.params);

  const formatCrumb = ({ acc, c, i, a, name }) => ({
    key: c,
    as: i !== a.length - 1 ? Link : "",
    to: acc.prevLink,
    content: name || capitalize(truncate(c, { length: 15 })),
    active: i === a.length - 1,
  });

  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const getSlugsFromRoute = () => {
    const path = route.url.split("/");
    const { params } = route;
    // if the last element in paths is an "", we are on the home page
    const onHome = [...path].pop() === "";
    const routes = path.reduce(
      (acc, c, i, a) => {
        if (c.length < 1) {
          return acc;
        }
        // if c is a uuid, getNamesFromIds
        const names = regexExp.test(c) && getNames({ params, c });

        acc.prevLink = acc.prevLink.concat(`/${c}`);
        acc.result = [...acc.result, formatCrumb({ acc, c, i, a, name: names[c] })];
        return acc;
      },
      {
        prevLink: "",
        result: [{ key: "Home", content: "CQ", as: onHome ? "" : Link, to: "/" }],
      }
    );
    return routes;
  };

  return getSlugsFromRoute;
};

const BreadcrumbLinks = () => {
  const route = useRouteMatch();
  const getSlugsFromRoute = useBreadcrumbs({ route });
  const crumbs = getSlugsFromRoute();

  return (
    <Breadcrumb
      data-test="breadcrumbs"
      icon="right angle"
      sections={crumbs.result}
    />
  );
};

export default BreadcrumbLinks;
