import React from "react";
import { Button, Grid } from "semantic-ui-react";

export const FormButtons = ({ formControl }) => (
  <>
    <Grid columns={1}>
      <Grid.Column textAlign="right">
        <Button
          data-test={`form_${formControl.formAction}_cancel_button`}
          size="tiny"
          onClick={formControl.reset}
        >
          Cancel
        </Button>
        <Button
          data-test={`form_${formControl.formAction}_submit_button`}
          content="Submit"
          size="tiny"
          primary
          disabled={formControl.isInvalid}
          onClick={formControl.submit}
        />
      </Grid.Column>
    </Grid>
  </>
);

export const FormButtonsDelete = ({ formControl }) => (
  <>
    <Grid columns={2}>
      <Grid.Column textAlign="left">
        <Button
          data-test={`form_${formControl.formAction}_confirm_button`}
          size="tiny"
          color="red"
          content="Delete"
          onClick={formControl.submit}
        />
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button
          data-test={`form_${formControl.formAction}_cancel_button`}
          size="tiny"
          onClick={formControl.reset}
        >
          Cancel
        </Button>
      </Grid.Column>
    </Grid>
  </>
);

export const FormButtonsCancel = ({ formControl }) => (
  <>
    <Grid columns={2}>
      <Grid.Column textAlign="left">
        <Button
          data-test={`form_${formControl.formAction}_confirm_button`}
          size="tiny"
          color="red"
          content="Cancel"
          onClick={formControl.submit}
        />
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button
          data-test={`form_${formControl.formAction}_cancel_button`}
          size="tiny"
          onClick={formControl.reset}
        >
          Go Back
        </Button>
      </Grid.Column>
    </Grid>
  </>
);
