import React from "react";
import { Segment } from "semantic-ui-react";

const ListHeading = ({ title, children }) => (
  <Segment className="table-list-heading">
    <h4 data-test="list-heading-title">{title}</h4>
    {children}
  </Segment>
);

export default ListHeading;
