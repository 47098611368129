import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Form, Checkbox } from "semantic-ui-react";
import { fieldStateSelector } from "store/formState";

export const ToggleInput = ({ fieldId, fieldName, setVal, testid, ...props }) => {
  const [fieldState, setFieldState] = useRecoilState(fieldStateSelector(fieldId));

  useEffect(() => {
    setFieldState({ type: "setVal", value: setVal ?? false });
  }, [setVal, setFieldState]);

  return (
    <Form.Field data-testid={testid}>
      <Checkbox
        toggle
        id={fieldId}
        name={fieldName}
        checked={fieldState.value}
        onClick={(e) => setFieldState({ type: e.type, value: e.target.checked })}
        {...props}
      />
    </Form.Field>
  );
};

export default ToggleInput;
