import { atom, selectorFamily } from "recoil";
import { makeRequest, ROUTES } from "services/httpService";
import { refetchIdFamily } from "store/formState";
import { refetchKeys } from "config/formConfig";

export const projectSelectedState = atom({
  key: "projectSelectedState",
  default: null,
});

export const getProjectList = selectorFamily({
  key: "getProjectList",
  get:
    (params) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getProjectsList));
      const res = await makeRequest(ROUTES.getProjectsList(params));
      const projects = res.data ? res.data.projects : /* istanbul ignore next */ [];
      const projectsById = projects.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

      return {
        projects,
        projectsById,
        getProjectDetails: (id) => projectsById?.[id],
      };
    },
});

export const getProjectMachineAccess = selectorFamily({
  key: "getProjectMachineAccess",
  get:
    (params) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getOrgProjectMachineAccess));
      const res = await makeRequest(ROUTES.getOrgProjectMachinesAccessList(params));
      const machines = res.data
        ? res.data.access_list
        : /* istanbul ignore next */ [];

      const machinesById = machines.reduce(
        (acc, v) => ({ ...acc, [v.machine_id]: v }),
        {}
      );

      return {
        machines,
        machinesById,
        getMachineDetails: (id) => machinesById?.[id],
      };
    },
});

export const getOrgProjectMachinesScheduleReservations = selectorFamily({
  key: "getOrgProjectMachinesScheduleReservations",
  get:
    ({ orgId, projectId, machineId, start, end }) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getOrgProjectMachinesScheduleReservations));
      const res = await makeRequest(
        ROUTES.getOrgProjectMachinesScheduleReservations(
          orgId,
          projectId,
          machineId,
          start,
          end
        )
      );
      const blocks = res.data ? res.data.blocks : /* istanbul ignore next */ [];
      const blocksById = blocks.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

      return { blocks, blocksById, getBlockDetails: (id) => blocksById?.[id] };
    },
});

export const postOrgProjectMachinesScheduleReservation = selectorFamily({
  key: "postOrgProjectMachinesScheduleReservation",
  post:
    ({ orgId, projectId, machineId, start, end, message }) =>
    async () => {
      const res = await makeRequest(
        ROUTES.postOrgProjectMachinesScheduleReservation(
          orgId,
          projectId,
          machineId,
          start,
          end,
          message
        )
      );
      return res.data ? res.data.blocks : /* istanbul ignore next */ [];
    },
});

export const getProjectUsersList = selectorFamily({
  key: "getProjectUsersList",
  get:
    (params) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getProjectUsersList));
      const res = await makeRequest(ROUTES.getProjectUsersList(params));
      const projectUsers = res.data ? res.data.users : /* istanbul ignore next */ [];
      const projectUsersById = projectUsers.reduce(
        (acc, v) => ({ ...acc, [v.id]: v }),
        {}
      );

      return {
        projectUsers,
        projectUsersById,
        getProjectUserDetails: (id) => projectUsersById?.[id],
      };
    },
});
