import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { editDrawerOpenState, machineSelectedState } from "store";
import { WithSuspense } from "components/common/util";
import { TableMain } from "components/common/table";
import { StatusIcon } from "components/common/ui";

const MachineAccessList = ({ data, currPath, tableHeaders }) => {
  const [selectedMachine, setSelectedMachine] = useRecoilState(machineSelectedState);
  const [drawerState, setDrawerState] = useRecoilState(editDrawerOpenState);

  const handleDrawerChange = () => !drawerState && setDrawerState(true);
  const handleSelectionChange = (id) =>
    id !== selectedMachine && setSelectedMachine(id);

  const openDrawer = (id) => {
    handleSelectionChange(id);
    handleDrawerChange();
  };

  const tableData = data.map((item) => ({
    id: item.machine_id,
    name: item.machine_name,
    link: <Link to={`${currPath}/${item.machine_id}`}>{item.machine_name}</Link>,
    schedule: <Link to={`${currPath}/${item.machine_id}/schedule`}>View</Link>,
    enabled: <StatusIcon value={item.access.access_enabled} />,
    block: <StatusIcon value={item.access.block_scheduling_enabled} />,
    priority: <StatusIcon value={item.access.priority_scheduling_enabled} />,
    default_priority: item.access.default_priority,
  }));

  const tableConfig = {
    data: tableData,
    selectedId: selectedMachine,
    clickHandler: (id) => openDrawer(id),
    headers: tableHeaders,
  };

  return (
    <WithSuspense>
      <TableMain testTarget="machine_access_list" tableConfig={tableConfig} />
    </WithSuspense>
  );
};

export default MachineAccessList;
