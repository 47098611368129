import React from "react";
import { Icon } from "semantic-ui-react";

const StatusIcon = ({ value, size, ...props }) => (
  <Icon
    color={value ? "green" : "red"}
    size={size}
    name={value ? "check circle outline" : "times circle outline"}
    {...props}
  />
);

export default StatusIcon;
