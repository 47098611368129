import React from "react";
import { Header, Table, Container } from "semantic-ui-react";

const CookiePolicy = () => (
  <Container fluid className="cookie-policy">
    <p>
      This Cookie Policy explains how ColdQuanta, Inc. (&quot;ColdQuanta&quot;,
      &quot;
      <b>we</b>
      &quot;, &quot;<b>us</b>&quot; or &quot;<b>our</b>&quot;) uses cookies and
      similar technologies in connection with the https://cloud.coldquanta.com
      website and any other website that we own or control and which posts or links
      to this Cookie Policy (collectively, the &quot;<b>Sites</b>&quot;).
    </p>
    <Header as="h3">What are cookies?</Header>
    <p>
      Cookies are small data files that are placed on your computer or mobile device
      when you visit a website. Cookies serve different purposes, like helping us
      understand how a site is being used, letting you navigate between pages
      efficiently, remembering your preferences and generally improving your browsing
      experience.
    </p>
    <p>
      Our Sites may use both session cookies (which expire once you close your web
      browser) and persistent cookies (which stay on your computer or mobile device
      until you delete them).
    </p>
    <p>
      We use two broad categories of cookies: (1) first party cookies, served
      directly by us to your computer or mobile device, which we use to recognize
      your computer or mobile device when it revisits our Sites; and (2) third party
      cookies, which are served by service providers or business partners on our
      Sites, and can be used by these parties to recognize your computer or mobile
      device when it visits other websites. Third party cookies can be used for a
      variety of purposes, including site analytics, advertising and social media
      features.
    </p>
    <Header as="h3">
      What types of cookies and similar tracking technologies does Cold Quanta use on
      the Sites?
    </Header>
    <p>
      On the Sites, we use cookies and other tracking technologies in the following
      categories described in the table below.
    </p>

    <Table celled padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>
            Who serves the cookies (link to privacy policy/site)
          </Table.HeaderCell>
          <Table.HeaderCell>How to control them</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Functionality / performance</Table.Cell>
          <Table.Cell>
            These cookies enhance the performance and functionality of our Service.
          </Table.Cell>
          <Table.Cell>
            <a
              href="http://www.auth0.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Auth0
            </a>
          </Table.Cell>
          <Table.Cell>See &apos;your choices&apos; below.</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <Header as="h3">Other technologies</Header>
    <p>
      In addition to cookies, our Sites may use other technologies, such as Flash
      technology and pixel tags to collect information automatically.
    </p>
    <p>Browser Web Storage</p>
    <p>
      We may use browser web storage (including via HTML5), also known as locally
      stored objects
    </p>
    <p>
      (&quot;LSOs&quot;), for similar purposes as cookies. Browser web storage
      enables the storage of a larger amount of data than cookies. Your web browser
      may provide functionality to clear your browser web storage.
    </p>
    <p>Web Beacons</p>
    <p>
      We may also use web beacons (which are also known as pixel tags and clear GIFs)
      on our Sites and in our HTML formatted emails to track the actions of users on
      our Sites and interactions with our emails. Unlike cookies, which are stored on
      the hard drive of your computer or mobile device by a website, pixel tags are
      embedded invisibly on webpages or within HTML formatted emails. Pixel tags are
      used to demonstrate that a webpage was accessed or that certain content was
      viewed, typically to measure the success of our marketing campaigns or
      engagement with our emails and to compile statistics about usage of the Sites,
      so that we can manage our content more effectively.
    </p>
    <h2>Your choices</h2>
    <p>
      Most browsers let you remove or reject cookies. To do this, follow the
      instructions in your browser settings. Many browsers accept cookies by default
      until you change your settings. Please note that if you set your browser to
      disable cookies, the Sites may not work properly.
    </p>
    <p>
      <a
        href="http://www.allaboutcookies.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        For more information about cookies, including how to see what cookies have
        been set on your computer or mobile device and how to manage and delete them,
        visit{" "}
      </a>
      <a
        href="http://www.allaboutcookies.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.allaboutcookies.org
      </a>
      . If you do not accept our cookies, you may experience some inconvenience in
      your use of our Sites. For example, we may not be able to recognize your
      computer or mobile device and you may need to log in every time you visit our
      Sites.
    </p>
    <p>
      For more information about how we collect, use and share your information, see
      our Privacy Policy:
    </p>
    <p>
      <a
        href="https://www.coldquanta.com/wp-content/uploads/2020/09/albertprivacypolicy.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.coldquanta.com/wp-content/uploads/2020/09/albertprivacypolicy.pdf
      </a>
    </p>
    <h2>Changes</h2>
    <p>
      Information about the cookies we use may be updated from time to time, so
      please check back on a regular basis for any changes.
    </p>
    <h2>Questions</h2>
    <p>
      If you have any questions about this Cookie Policy, please contact us by email
      at
    </p>
    <p>
      <a
        href="mailto:legal-notice@coldquanta.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        legal-notice@coldquanta.com
      </a>
      . Last modified 9/16/2020.
    </p>
  </Container>
);

export default CookiePolicy;
