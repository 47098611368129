import { useState, useEffect } from "react";
import auth from "./auth0Service";

const useAuth = () => {
  const [token, setToken] = useState(null);
  const [authState, setAuthState] = useState({
    error: null,
    isLoading: true,
    isAuthenticated: false,
    user: null,
  });

  useEffect(() => {
    (async () => {
      /* istanbul ignore else: path will never be taken in coverage reporting */
      if (process.env.REACT_APP_AUTH0_DOMAIN === "cypress") {
        setToken(true);
      } else {
        try {
          const jwt = await auth.getTokenSilently();
          if (jwt) {
            setToken(true);
          }
        } catch (e) {
          if (e.error === "login_required") {
            await auth.loginWithRedirect();
          }
          if (e.error === "consent_required") {
            await auth.loginWithRedirect();
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (token) {
        const getUser = await auth.getUser();

        setAuthState((prev) => ({
          ...prev,
          error: null,
          isLoading: false,
          isAuthenticated: true,
          user: getUser,
        }));
      }
    })();
  }, [token]);

  return { authState, logout: auth.logout };
};

export default useAuth;
