import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { fieldStateSelector } from "store/formState";
import { getTime } from "date-fns";
import { Form } from "semantic-ui-react";
import { dateTimeUtil } from "util/dateTimeUtil";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({
  fieldId,
  fieldName,
  setVal,
  compareFields,
  rules,
  testid,
  ...props
}) => {
  const [fieldState, setFieldState] = useRecoilState(fieldStateSelector(fieldId));

  useEffect(() => {
    /* istanbul ignore else */
    if (setVal) {
      setFieldState({ type: "setVal", value: setVal });
    }
  }, [setVal, setFieldState]);

  return (
    <Form.Field
      data-testid={testid}
      control={DatePicker}
      id={fieldId}
      name={fieldName}
      selected={fieldState.value ?? ""}
      showTimeSelect
      minDate={rules?.minDate}
      maxDate={rules?.maxDate}
      filterTime={(time = setVal) =>
        dateTimeUtil.filterTimeRange(time, rules?.minDate, rules?.maxDate)
      }
      timeIntervals={rules?.timeIntervals}
      onChange={(e) =>
        setFieldState({ type: "blur", value: getTime(e), compareFields })
      }
      dateFormat={rules?.dateFormat ?? "MMMM d, yyyy h:mm aa"}
      placeholderText={rules?.placeholderText ?? "Click to select a date"}
      {...props}
      error={
        fieldState?.errors && {
          content: fieldState?.errors?.errMessage[0],
          pointing: "above",
        }
      }
    />
  );
};

export default DateTimePicker;
