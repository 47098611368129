import React from "react";
import { Card, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

const UserCard = ({ user, logout }) => {
  const getJoinDate = (dateString) => {
    const date = new Date(dateString);
    const joinDate = `${date.getFullYear()}`;
    return joinDate;
  };

  return (
    <Card data-test="user-card">
      <Card.Content>
        <Card.Header>{user?.name}</Card.Header>
        <Card.Meta>
          <span>{user?.email}</span>
        </Card.Meta>
        <Card.Description>Joined {getJoinDate(user?.updated_at)}</Card.Description>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button data-test="logout-button" size="mini" onClick={async () => logout()}>
          Log Out
        </Button>
      </Card.Content>
    </Card>
  );
};

export default withRouter(UserCard);
