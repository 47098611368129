import React from "react";
import { FormButtonsDelete, HiddenInput } from "components/common/form";
import { Message } from "semantic-ui-react";

const MachineAccessDelete = ({ data, ...props }) => {
  const formData = {
    machineId: data?.machine_id,
    machineName: data?.machine_name,
    accessEnabled: data?.access?.access_enabled,
    blockSchedulingEnabled: data?.access?.block_scheduling_enabled,
    prioritySchedulingEnabled: data?.access?.priority_scheduling_enabled,
    defaultPriority: data?.access?.default_priority,
  };

  return <MachineAccessDeleteForm formData={formData} {...props} />;
};

const MachineAccessDeleteForm = ({ formControl, formData }) => (
  <>
    <Message negative data-test="delete_confirm_message">
      Remove access to {formData?.machineName}?
    </Message>
    <HiddenInput
      fieldId="machineId"
      fieldName="machineId"
      setVal={formData?.machineId}
    />
    <FormButtonsDelete formControl={formControl} />
  </>
);

export default MachineAccessDelete;
