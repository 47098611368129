import React from "react";
import { Menu } from "semantic-ui-react";
import { BreadcrumbLinks } from "components/common/ui";
import { UserInfo } from "components/common/ui/UserInfo";
import useAuth from "services/useAuth";
import WithSuspense from "components/common/util/WithSuspense";

const TopBar = () => {
  const { authState } = useAuth();

  return (
    <Menu
      fixed="top"
      secondary
      className="global-top-bar"
      data-test="layout-top-bar"
    >
      <Menu.Item>
        <WithSuspense>
          <BreadcrumbLinks />
        </WithSuspense>
      </Menu.Item>
      <Menu.Menu position="right" data-test="top-bar-user-icon">
        <Menu.Item name="user">
          {authState.isAuthenticated && <UserInfo />}
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default TopBar;
