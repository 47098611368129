import React from "react";
import { StatusIcon } from "components/common/ui";
import { Header } from "semantic-ui-react";

const MachineAccessDetails = ({ machine, children }) =>
  machine && machine.machine_id ? (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "3fr 1fr" }}>
        <Header as="h3">Machine Details</Header>
      </div>
      <Header as="h5" color="grey">
        Machine Name
      </Header>
      {machine.machine_name}
      <Header as="h5" color="grey">
        Machine Id
      </Header>
      {machine.machine_id}
      <Header as="h5" color="grey">
        Access Properties
      </Header>
      <ul className="key-value-container">
        <li className="key-value">Access Enabled</li>
        <li className="key-value">
          <StatusIcon size="large" value={machine.access.access_enabled} />
        </li>
        <li className="key-value">Block Scheduling</li>
        <li className="key-value">
          <StatusIcon size="large" value={machine.access.block_scheduling_enabled} />
        </li>
        <li className="key-value">Priority Scheduling</li>
        <li className="key-value">
          <StatusIcon
            size="large"
            value={machine.access.priority_scheduling_enabled}
          />
        </li>
        <li className="key-value">Default Priority</li>
        <li className="key-value">{machine.access.default_priority}</li>
      </ul>
      <div>{children}</div>
    </>
  ) : (
    <div />
  );

export default MachineAccessDetails;
