import { selector, selectorFamily, atom } from "recoil";
import { makeRequest, ROUTES } from "services/httpService";
import { refetchIdFamily } from "store/formState";
import { refetchKeys } from "config/formConfig";

export const getOrgList = selector({
  key: "getOrgList",
  get: async ({ get }) => {
    get(refetchIdFamily(refetchKeys.getOrgList));
    const res = await makeRequest(ROUTES.getOrgList);
    const orgs = res.data ? res.data.orgs : /* istanbul ignore next */ [];
    const orgsById = orgs.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

    return {
      orgs,
      orgsById,
      getOrgDetails: (id) => orgsById?.[id],
    };
  },
});

export const orgListState = atom({
  key: "orgListState",
  default: getOrgList.orgs,
});

export const getOrgMachineAccessList = selectorFamily({
  key: "getOrgMachineAccessList",
  get:
    (params) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getOrgMachineAccessList));
      const res = await makeRequest(ROUTES.getOrgMachineAccessList(params));
      const machines = res.data
        ? res.data.access_list
        : /* istanbul ignore next */ [];
      const machinesById = machines.reduce(
        (acc, v) => ({ ...acc, [v.machine_id]: v }),
        {}
      );

      return {
        machines,
        machinesById,
        getMachineDetails: (id) => machinesById?.[id],
      };
    },
});

export const getOrgUsersList = selectorFamily({
  key: "getOrgUsersList",
  get:
    (params) =>
    async ({ get }) => {
      get(refetchIdFamily(refetchKeys.getOrgUserList));
      const res = await makeRequest(ROUTES.getOrgUsers(params));
      const orgUsers = res.data ? res.data.users : /* istanbul ignore next */ [];
      const orgUsersById = orgUsers.reduce((acc, v) => ({ ...acc, [v.id]: v }), {});

      return {
        orgUsers,
        orgUsersById,
        getOrgUserDetails: (id) => orgUsersById?.[id],
      };
    },
});
