import { atom, atomFamily } from "recoil";

const navbarOpenState = atom({
  key: "navbarOpenState",
  default: false,
});

// TODO: convert all remaining instances of "editDrawerOpenState" to "drawerOpenState"
const editDrawerOpenState = atom({
  key: "editDrawerOpenState",
  default: false,
});

const drawerOpenState = atomFamily({
  key: "drawerOpenState",
  default: false,
});

const modalOpenState = atomFamily({
  key: "modalOpenState",
  default: false,
});

const showDeleteConfirm = atomFamily({
  key: "showDeleteConfirm",
  default: false,
});

export {
  navbarOpenState,
  editDrawerOpenState,
  drawerOpenState,
  modalOpenState,
  showDeleteConfirm,
};
