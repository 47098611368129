import React from "react";
import usePermissions from "services/usePermissions";
import { permsMap } from "config/generalConfig";

const WithPerms = ({ perm, route, children }) => {
  const { findPerm } = usePermissions({ route });

  return findPerm(permsMap[perm]) ? <>{children}</> : <></>;
};

export default WithPerms;
