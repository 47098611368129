import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Form, Input } from "semantic-ui-react";
import { fieldStateSelector } from "store/formState";
import classNames from "classnames";

export const StrInput = ({ fieldId, fieldName, setVal, ...props }) => {
  const [fieldState, setFieldState] = useRecoilState(fieldStateSelector(fieldId));

  useEffect(() => {
    /* istanbul ignore else */
    if (setVal !== undefined || setVal !== null) {
      setFieldState({ type: "setVal", value: setVal });
    }
  }, [setVal, setFieldState]);

  return (
    <Form.Field
      id={fieldId}
      control={Input}
      className={classNames("formField", { readOnly: props.readOnly })}
      fluid
      name={fieldName}
      onChange={(e) => setFieldState({ type: e.type, value: e.target.value })}
      onBlur={(e) => setFieldState({ type: e.type, value: e.target.value })}
      value={fieldState?.value ?? ""}
      error={
        fieldState?.errors && {
          content: fieldState?.errors?.errMessage[0],
          pointing: "above",
        }
      }
      {...props}
    >
      {props.children}
    </Form.Field>
  );
};

export default StrInput;
