import { format, formatDuration, intervalToDuration } from "date-fns";

const toReadable = (millis) => format(millis, "MMMM d, yyyy h:mm aa");

const filterTimeRange = (time, minDate, maxDate) => {
  const selected = new Date(time).getTime();
  const sMin = new Date(minDate).getTime();
  const sMax = new Date(maxDate).getTime();

  return selected >= sMin && selected <= sMax;
};

const getDuration = (startMillis, endMillis) => {
  const diff = endMillis - startMillis;

  return diff < 1000
    ? `${diff / 1000} seconds`
    : formatDuration(
        intervalToDuration({
          start: new Date(startMillis),
          end: new Date(endMillis),
        })
      );
};

export const convertDate = {
  toReadable,
};

export const dateTimeUtil = {
  filterTimeRange,
  getDuration,
};
