import React from "react";
import useAuth from "services/useAuth";
import { Popup, Image } from "semantic-ui-react";
import UserCard from "./UserCard";

export const UserInfo = () => {
  const {
    authState: { user },
    logout,
  } = useAuth();

  return (
    <Popup
      on="click"
      content={<UserCard user={user} logout={logout} />}
      position="bottom right"
      key={user && user.name}
      trigger={<Image src={user ? user.picture : "/no_avatar.jpg"} avatar />}
    />
  );
};

export default UserInfo;
