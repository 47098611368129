import React from "react";
import { Table } from "semantic-ui-react";
import TableCells from "./TableCells";

const EmptyRow = () => (
  <Table.Row>
    <Table.Cell>No data</Table.Cell>
  </Table.Row>
);

const TableRows = ({ data, headers, clickHandler = () => null }) =>
  data && data?.length > 0 ? (
    data.map((entry) => (
      <Table.Row
        data-test={entry.id}
        key={entry.id}
        onClick={() => clickHandler(entry.id)}
      >
        {TableCells(entry, headers)}
      </Table.Row>
    ))
  ) : (
    <EmptyRow />
  );

export default TableRows;
